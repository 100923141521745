import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CachedIcon from '@material-ui/icons/Cached';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import SpinnerButton from '../../components/SpinnerButton';
import SuccessBanner from '../../components/SuccessBanner';
import ErrorBanner from '../../components/ErrorBanner';
import InvalidURLParamsScreen from '../../components/InvalidURLParamsScreen';

import useAsyncCallback from '../../hooks/useAsyncCallback';

import * as auth from '../../api/auth';
import { validatePasswordPair } from '../../common/validatepassword';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light,
    },
    form: {
        marginTop: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    }
}));


export default function PasswordResetScreen() {
    const classes = useStyles();

    const [resetPassword, {isPending, isSuccess, errorMsg}] = useAsyncCallback(auth.resetPassword);
    const disableForm = isPending || isSuccess;

    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const validity = validatePasswordPair(password1, password2);

    // once successful then redirect page after a short period
    const [doRedirect, setDoDirect] = React.useState(false);
    React.useEffect(() => { 
        if (isSuccess) {
            setTimeout(() => {
                setDoDirect(true);
            }, 1000);
        }
    }, [isSuccess]);

    // extract query params from URL
    const queryParams = {};
    for (const pair of new URLSearchParams(window.location.search)) {
        queryParams[pair[0]] = pair[1];
    }

    // these must match the URL constructed by the Cognito custom-message lambda
    const {name, user, code} = queryParams;

    if (!(user && code && name)) {
        return <InvalidURLParamsScreen/>;
    }

    const handleSubmitClick = (event) => {
        event.preventDefault();
        resetPassword(user, code, password1);
    };

    return (
        <Container className={classes.container} maxWidth="xs">
            <Avatar className={classes.avatar}>
                <CachedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Reset Your Password</Typography>
            <Typography>{name}, enter your new password below:</Typography>

            <form onSubmit={handleSubmitClick} className={classes.form}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password1"
                    label="New Password"
                    type="password"
                    disabled={disableForm}
                    helperText={validity.password1.msg || ' '}
                    onChange={event => setPassword1(event.target.value)}
                />

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password2"
                    label="New Password (Again)"
                    type="password"
                    disabled={disableForm}
                    helperText={validity.password2.msg || ' '}
                    onChange={event => setPassword2(event.target.value)}
                />

                <SpinnerButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!validity.allValid || disableForm}
                    pending={isPending}
                    className={classes.button}>
                        Confirm
                </SpinnerButton>
            </form>

            {isSuccess && <SuccessBanner text="Password Updated" />}
            <ErrorBanner text={errorMsg} />

            {doRedirect && <Redirect to="/signin"/>}
        </Container>
    );
}

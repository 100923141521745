import React from 'react';
import { Link, useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import PricingPlans from '../../components/pricingplans';
import AddToSlackButton from '../../components/AddToSlackButton';
import InviteToChannelInstruction from '../../components/InviteToChannelInstruction';

import useSetupStatus from '../../hooks/useSetupStatus';
import useActions from '../../hooks/useActions';
import useAsyncCallback from '../../hooks/useAsyncCallback';

import parseQueryParams from '../../common/parsequeryparams';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(12),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        padding: theme.spacing(5),
        width: '60%'
    },
    payplanPaper: {
        width: '90%'
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(4)
    },
    slackButton: {
        margin: theme.spacing(3)
    },
    successAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.success.main,
    },
    continueButton: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(1),
        width: '50%'
    },
    spinner: {
        marginTop: theme.spacing(1)
    }
}));

function LoadingContent() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Typography component="h1" variant="h5">Loading...</Typography>
            <CircularProgress disableShrink className={classes.spinner}/>
        </Paper>
    );
}

function SetupWizard({step}) {
    const classes = useStyles();

    if (step === 'slack') {
        const {success, next} = parseQueryParams();
        const isSuccess = success != null;
        const nextPage = '/welcome/invite-to-channel' + (next ? `?next=${next}` : '');

        if (isSuccess) {
            return (
                <Paper className={classes.paper}>
                    <div className={classes.headerContent}>
                        <Avatar className={classes.successAvatar}>
                            <CheckIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" className={classes.header}>Slack App Installed</Typography>
                    </div>
                    <Typography variant="body1">🥳 <b>Team Feels</b> is successfully installed in your Slack Workspace.</Typography>
                    <Button
                        variant="contained" 
                        color="primary"
                        className={classes.continueButton}
                        component={Link}
                        to={nextPage}>Next<ArrowRightIcon/></Button>
                </Paper>
            );
        }

        return (
            <Paper className={classes.paper}>
                <div className={classes.headerContent}>
                    <Typography variant="h4">Welcome</Typography>
                    <Typography variant="h5">👋 Let's Get Started 👍</Typography>
                </div>
                <Typography variant="body1"><b>First Step:</b> Connect up your Slack workspace or organization.</Typography>
                <Typography variant="body2">You can add more workspaces later from the Account Settings.</Typography>
                <div className={classes.slackButton}><AddToSlackButton /></div>
            </Paper>
        );
    }

    if (step === 'invite-to-channel') {
        const {next, onLogin} = parseQueryParams();
        const isOnLogin = onLogin != null;
        const nextPage = '/' + (next ? decodeURIComponent(next) : 'welcome/create-a-survey');

        return (
            <Paper className={classes.paper}>
                <div className={classes.headerContent}>
                    <Typography variant="h5">{isOnLogin ? 'Looks like our bot is not yet invited to any channels' : 'Invite the bot'} 🤖</Typography>
                    <Typography variant="subtitle">To post to a channel our app must be invited first!</Typography>
                </div>
                
                <InviteToChannelInstruction callToActionText="Paste this into at least one Slack channel:" />

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.continueButton}
                    component={Link}
                    to={nextPage}>Next<ArrowRightIcon/></Button>
            </Paper>
        );
    }

    if (step === 'create-a-survey') {
        return (
            <Paper className={classes.paper}>
                <div className={classes.headerContent}>
                    <Typography variant="h4">You're Ready</Typography>
                    <Typography variant="h5">To Create Your First Survey 🎉</Typography>
                </div>
                <Typography variant="body1"><b>Final Step:</b> Time to get creative. Use our editor to make your first survey 👇</Typography>
                <Button
                        variant="contained"
                        color="primary"
                        className={classes.continueButton}
                        component={Link}
                        to="/add">Let's Go<ArrowRightIcon/></Button>
            </Paper>
        );
    }

    return <Redirect to="/" />   
}

function TrialExpired() {
    const classes = useStyles();

    const actions = useActions();
    const [startSubscriptionPlan, {isPending}] = useAsyncCallback(actions.startSubscriptionPlan);

    return (
        <Paper className={`${classes.paper} ${classes.payplanPaper}`}>
            <div className={classes.headerContent}>
                <Typography variant="subtitle1">Your free trial has finished</Typography>
                <Typography variant="h5">Select a payment plan to continue...</Typography>
                <Typography variant="caption">Change your plan at any time in Account Settings</Typography>
            </div>
            <PricingPlans loading={isPending} onStartPlan={plan => startSubscriptionPlan(plan.planID)} />
        </Paper>
    );
}


export default function WelcomeScreen() {
    const classes = useStyles();

    const {
        isChecking,
        needsSlackConfiguring,
        needsChannelInvite,
        trialExpired,
        isOnPaymentPlan
    } = useSetupStatus();

    // make the step param 'sticky', otherwise when the param goes to undefined during
    // the CSS transition we end up being hitting the Redirect below
    const {step:stepParam} = useParams();
    const [step, setStep] = React.useState(stepParam);                          
    React.useEffect(() => {
        if (stepParam) { setStep(stepParam); }
    }, [stepParam]);

    console.log('step: ' + step + ' ' + stepParam);
    
    return (
        <Container className={classes.container}>
            {isChecking ? <LoadingContent /> :
            trialExpired && !isOnPaymentPlan ? <TrialExpired /> :
            !step && needsSlackConfiguring ? <Redirect to="/welcome/slack"/> :
            !step && needsChannelInvite ? <Redirect to="/welcome/invite-to-channel?onLogin"/> :
            step ? <SetupWizard step={step}/> :
            <Redirect to="/" /> }
        </Container>
    );
}
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useSharedSurvey(sharedSurveyId) {
    const dispatch = useDispatch();
    const [loadSurvey, {isFinished, errorMsg}] = useAsyncCallback(actions.loadSharedSurvey(sharedSurveyId));
    const survey = useSelector(state => state.surveys.shared);
    const surveyFound = !!survey && survey.id === sharedSurveyId;
    const isLoading = !(surveyFound || isFinished);

    useEffect(() => {
        if (!surveyFound) { // initiate load
            dispatch(loadSurvey);
        }
    }, [surveyFound]);

    return [
        survey,
        isLoading,
        errorMsg
    ];
}
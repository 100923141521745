import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import moment from 'moment'


const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex'
    }
}));

export default function DateTimeOrNowPicker({datetime, isNow, onChangeDateTime, onChangeIsNow, disablePast, maxFutureDays}) {
    const classes = useStyles();

    const handleIsNowChange = (event) => {
        onChangeIsNow(event.target.checked);
    };

    return (
        <div className={classes.wrapper}>
            <FormControlLabel control={<Checkbox checked={isNow} onChange={handleIsNowChange}/>} label="Now" />

            { !isNow &&
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        fullWidth
                        required
                        value={datetime}
                        onChange={onChangeDateTime}
                        animateYearScrolling
                        disablePast={disablePast}
                        maxDate={maxFutureDays ? moment().add(maxFutureDays, 'days') : null}
                    />

                    <TimePicker
                        fullWidth
                        required
                        ampm={false}
                        value={datetime}
                        minutesStep={5}
                        onChange={onChangeDateTime}
                    />
                </MuiPickersUtilsProvider>
            }
        </div>
    );
}

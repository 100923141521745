import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useReplies(survey, isShared) {
    // survey is sometimes null (for publically shared dashboard) so default to an 'empty' object
    survey = !survey ? {config: {}} : survey;

    const dispatch = useDispatch();
    const [listReplies, {isPending, result:replies, errorMsg}] = useAsyncCallback(
        actions.listReplies(
                survey.id,
                survey.config.workspaceOrEnterpriseId,
                survey.config.isAnon,
                isShared));

    useEffect(() => {
        if (survey.id) {
            dispatch(listReplies);
        }
    }, [survey.id]);

    return [
        replies ? replies : {},
        isPending,
        errorMsg
    ];
}
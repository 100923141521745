import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import useUserID from '../hooks/useUserID';

const useStyles = makeStyles(theme => ({
    button: {
        width: 139,
        height: 40,
        display: 'block',
        '&:hover': {
            transform: 'scale(0.95)',
            boxShadow: '0 0 10px 0px #fff'
        },
        transition: 'all 0.1s'
    }
}));

export default function AddToSlackButton({nextPage, disabled=false, ...props}) {
    const classes = useStyles();

    const {userId, isPending} = useUserID();
    const state = encodeURIComponent(JSON.stringify([
        userId,
        nextPage
    ]));

    return (
        <a {...props} href={isPending || disabled ? '#' : `https://slack.com/oauth/v2/authorize?scope=chat:write,channels:read,users:read&client_id=1135625851893.1124675994503&state=${state}`}>
            <img className={classes.button} alt="Add to Slack" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
        </a>
    );
}




import React from 'react';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator'

import SimpleMenuButton from './SimpleMenuButton';

const useStyles = makeStyles(theme => ({
    spacer: {
        flexGrow: 1
    },
    username: {
        margin: theme.spacing(0, 0.5, 0, 1),
    },
    productName: {
        textDecoration: 'none',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center'
    },
    pageTab: {
        marginRight: theme.spacing(3),
        backgroundColor: '#4b5dd0',
        '&:hover': {
            backgroundColor: lighten('#4b5dd0', 0.05)
        },
        '&.Mui-disabled': {
            color: theme.palette.primary.contrastText,
        }
    },
}));

function UserMenu({username, menuItems}) {
    const classes = useStyles();

    return (
        <SimpleMenuButton
            btnElement={Button}
            color="inherit"
            items={menuItems}>
                <AccountCircle/>
                <span className={classes.username}>{username}</span>
                <KeyboardArrowDownIcon fontSize="small"/>
        </SimpleMenuButton>
    );
}

export default function MainAppBar({appName, username, menuItems, navButton, ...props}) {
    const classes = useStyles();
    const {pathname} = useLocation();

    return (
        <AppBar {...props} position="static">
            <Toolbar>
                <Typography variant="h4" className={classes.productName} component={ReactRouterLink} to={navButton.path}>
                    {appName}
                </Typography>
                
                <div className={classes.spacer}></div>
                {navButton
                    ? <Button
                        className={classes.pageTab}
                        disableElevation
                        variant="contained"
                        color="primary"
                        disabled={pathname === navButton.path}
                        component={ReactRouterLink} to={navButton.path}
                        startIcon={navButton.icon}>
                            {navButton.text}
                      </Button>
                    : null
                }
                <UserMenu username={username} menuItems={menuItems}/>
            </Toolbar>
        </AppBar>
    );
}
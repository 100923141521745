import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'


const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& :first-child': {
            flexGrow: 1
        },
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
        borderColor: 'rgba(0, 0, 0, 0.08)'
    },
    popper: {
        zIndex: 1 // needed to cover up textfields: https://github.com/mui-org/material-ui/issues/18333
    },
    emojibutton: {
        fontSize: '2em',
        lineHeight: '1',
        margin: '2px',
        backgroundColor: theme.palette.background.default,
    }
}));

function EmojiButton({onDelete, children, disabled, ...props}) {
    const classes = useStyles();

    const [isHovering, setHovering] = React.useState(false);
    const onStartHover = disabled ? undefined : () => setHovering(true);
    const onEndHover = disabled ? undefined : () => setHovering(false);

    const closeIcon = <IconButton size="small" color="secondary" onClick={onDelete}><CloseIcon /></IconButton>;

    return (
        <Badge badgeContent={closeIcon} onMouseEnter={onStartHover} onMouseLeave={onEndHover} invisible={!isHovering}>
            <Button variant="outlined" disabled={disabled} className={classes.emojibutton} {...props}>{children}</Button>
        </Badge>
    );
}

export default function EmojiStrip({emojis, onChange, disabled}) {
    const classes = useStyles();

    const [editingIdx, setEditingIdx] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ignoreClose, setIgnoreClose] = React.useState(false);

    const openEmojiPopper = (event) => {
        setIgnoreClose(!!anchorEl); // don't close if just changing anchor
        setAnchorEl(event.currentTarget);
        setEditingIdx(null);
    };

    const openEditEmojiPopper = (event, idx) => {
        openEmojiPopper(event);
        setEditingIdx(idx);
    };

    const closeEmojiPopper = () => {
        if (ignoreClose) {
            setIgnoreClose(false);
            return;
        }
        setAnchorEl(null);
    };

    const deleteEmoji = (idx) => {
        const copy = [...emojis];
        copy.splice(idx, 1);
        onChange(copy);
    };

    const selectEmoji = (emoji) => {
        if (editingIdx == null) { // not in edit mode so append a new emoji
            onChange([...emojis, emoji.native]);
        } else { // in edit mode so adjust existing emoji
            closeEmojiPopper();
            const copy = [...emojis];
            copy[editingIdx] = emoji.native;
            onChange(copy);
        }
    };

    return (
        <Paper className={classes.paper} variant="outlined">
            <div>
                {
                    emojis.length === 0 && <Typography variant="body1" color="textSecondary" align="right">Add some Emojis 👉</Typography>
                }
                {emojis.map((emoji, i) =>
                    <EmojiButton key={i} onClick={event => openEditEmojiPopper(event, i)} onDelete={() => deleteEmoji(i)} disabled={disabled}>{emoji}</EmojiButton>
                )}
            </div>
            <div>
                <IconButton color="primary" onClick={openEmojiPopper} disabled={disabled}><AddBoxIcon /></IconButton>
                <Popper
                    className={classes.popper}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement="top"
                    modifiers={{
                        flip: {
                            enabled: true,
                        }
                    }}
                >
                    <ClickAwayListener onClickAway={closeEmojiPopper}>
                        <Picker set='apple' title="Choose an Emoji" emoji="sauropod" autoFocus onSelect={selectEmoji} />
                    </ClickAwayListener>
                </Popper>
            </div>
        </Paper>
    );
}

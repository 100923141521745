import React from 'react';
import { useHistory, useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import PersonIcon from '@material-ui/icons/PersonOutline';
import EmailIcon from '@material-ui/icons/MailOutline';
import SubscriptionIcon from '@material-ui/icons/Autorenew';
import PaymentIcon from '@material-ui/icons/Payment';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../../components/PageTitle';
import VerticalTabs from '../../components/VerticalTabs';
import Personal from './personal';
import Notifications from './notifications';
import Subscription from './subscription';
import Payments from './payments';
import Integrations from './integrations';

import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(6),
        minWidth: '600px'
    },
    username: {
        color: theme.palette.text.hint
    },
    tabs: {
        marginTop: theme.spacing(2),
        minHeight: '500px'
    }
}));

export default function Account() {
    const classes = useStyles();

    const user = useUserInfo();
;
    const {tab} = useParams();
    const history = useHistory();
    const onChangeTab = newTab => history.push('/account/' + newTab);

    return (
        <Container className={classes.container}>
            <PageTitle>Account Settings <span className={classes.username}>({user.name})</span></PageTitle>
            <VerticalTabs className={classes.tabs} tabKey={tab || 'personal'} onChange={onChangeTab} tabs={[
                {key: 'personal', title: 'Personal', icon: <PersonIcon/>, content: <Personal/>},
                {key: 'notifications', title: 'Notifications', icon: <EmailIcon/>, content: <Notifications/>},
                {key: 'integrations', title: 'Integrations', icon: <CategoryIcon/>, content: <Integrations/>},
                {key: 'subscription', title: 'Subscription', icon: <SubscriptionIcon/>, content: <Subscription/>},
                {key: 'payments', title: 'Payments', icon: <PaymentIcon/>, content: <Payments/>}
            ]}/>
        </Container>
    );
}
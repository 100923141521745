import React from 'react';
import { Redirect } from "react-router-dom";

import useUserInfo from '../hooks/useUserInfo';


export default function RedirectIfNotLoggedIn() {
    const user = useUserInfo();

    if (user.isSignInRequired) {
        const redirectTo = encodeURIComponent(window.location.pathname + window.location.search);
        return <Redirect to={`/signin?p=${redirectTo}`} />;
    }

    return null;
}
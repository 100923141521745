import React from 'react';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import SubscriptionIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import PricingPlans from '../../../components/pricingplans';

import Header from '../Header';

import useSubscriptionDetails from '../../../hooks/useSubscriptionDetails';
import useActions from '../../../hooks/useActions';
import useAsyncCallback from '../../../hooks/useAsyncCallback';
import useToggle from '../../../hooks/useToggle';

import {formatAsMoney} from '../../../common/formatcurrency';
import {timeUntil} from '../../../common/formattime';


const usePageStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: '1em'
    },
    credit: {
        fontSize: '0.5em',
        opacity: '0.7',
        textAlign: 'center',
        '& a': {
            textDecoration: 'none'
        }
    },
    cancelSection: {
        marginTop: theme.spacing(3)
    },
    cancelButton: {
        width: '20em'
    }
}));


export default function Subscription() {
    const classes = usePageStyles();
    const { enqueueSnackbar } = useSnackbar();
    const actions = useActions();

    const [cancelDialogOpen, openCancelDialog, closeCancelDialog] = useToggle(false);

    const [{paddlePlanID, lastPayment, nextPayment}, {isOnTrial, trialExpiresAt}, isPlanLoadPending, planLoadError] = useSubscriptionDetails();

    React.useEffect(() => {
        if (planLoadError) {
            enqueueSnackbar(`Error loading current subscription: ${planLoadError}`, { variant: 'error' });
        }
    }, [planLoadError]);

    const [handleChangePlan, {isPending:isPlanChangePending}] = useAsyncCallback(async newPlan => {
        try {
            await actions.changeSubscriptionPlan(newPlan.planID);
        } catch (error) {
            enqueueSnackbar(`Error updating plan: ${error.message}`, { variant: 'error' });
            return;
        }

        enqueueSnackbar(<span>Welcome to the <strong>{newPlan.title}</strong> plan!</span>, { variant: 'success' });
    });

    const [handleStartSubscription, {isPending:isPlanStartPending}] = useAsyncCallback(async plan => {
        try {
            await actions.startSubscriptionPlan(plan.planID);
        } catch (error) {
            enqueueSnackbar(`Error loading plan: ${error.message}`, { variant: 'error' });
            return;
        }

        enqueueSnackbar(<span>Welcome to the <strong>{plan.title}</strong> plan!</span>, { variant: 'success' });
    });

    const [handleCancelSubscription, {isPending:isPlanCancelling}] = useAsyncCallback(async () => {
        try {
            closeCancelDialog();
            await actions.cancelSubscriptionPlan();
        } catch (error) {
            enqueueSnackbar(`Error cancelling plan: ${error.message}`, { variant: 'error' });
            return;
        }

        enqueueSnackbar(<span>Your plan has been cancelled.</span>, { variant: 'success' });
    });

    return (<>
        <Header icon={SubscriptionIcon}>Manage Subscription</Header>
        <Alert severity="success" className={classes.alert}>
            <Typography variant="body2">Choose a plan to best suit your needs. Upgrade or downgrade at any time.</Typography>
        </Alert>

        {isOnTrial && <Alert severity="info" className={classes.alert}>
            <Typography variant="body2"><b>Your free trial ends: </b>{timeUntil(trialExpiresAt)}</Typography>
        </Alert>}

        {lastPayment.date && nextPayment.date &&
            <Alert severity="info" className={classes.alert}>
                <AlertTitle>Your Plan Charges</AlertTitle>
                <Typography variant="body2"><b>Last Payment:</b> {formatAsMoney(lastPayment.amount, 'US', lastPayment.currency)} {lastPayment.date}</Typography>
                <Typography variant="body2"><b>Next Payment:</b> {formatAsMoney(nextPayment.amount, 'US', nextPayment.currency)} {nextPayment.date}</Typography>
            </Alert>
        }

        <PricingPlans paddlePlanID={paddlePlanID} loading={isPlanLoadPending || isPlanChangePending || isPlanStartPending || isPlanCancelling} onStartPlan={handleStartSubscription} onChangePlan={handleChangePlan} />

        {paddlePlanID &&
            <Alert severity="error" className={classes.cancelSection}>
                <AlertTitle>Cancelling Your Subscription</AlertTitle>
                Cancelling your subscription will disable your surveys. You can reactivate your subscription later.
                <Button variant="outlined" color="secondary" className={classes.cancelButton} onClick={openCancelDialog}>Cancel Subscription</Button>
            </Alert>
        }

        <Dialog open={cancelDialogOpen} onClose={closeCancelDialog}>
            <DialogTitle>Are you sure you want to cancel your subscription?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Cancelling will disable your surveys but will not delete any data. You can reactivate your subscription later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelSubscription} variant="outlined" color="secondary">
                    I Want To Cancel My Subscription
                </Button>
                <Button onClick={closeCancelDialog} variant="outlined" color="primary" autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useSurvey(surveyId) {
    const dispatch = useDispatch();
    const [loadSurveys, {isFinished, errorMsg}] = useAsyncCallback(actions.loadSurveys());
    const survey = useSelector(state => state.surveys.all.find(s => s.id === surveyId));
    const surveyFound = !!survey;
    const isLoading = !(surveyFound || isFinished);

    useEffect(() => {
        if (!surveyFound) { // try re-loading rules
            dispatch(loadSurveys);
        }
    }, [surveyFound]);

    return [
        survey,
        isLoading,
        errorMsg
    ];
}
import React from 'react';
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Cancel';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    headingBanner: {
        padding: '5px 20px',
        backgroundColor: '#eaecff',
        color: '#16205a',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '&>:first-child, &>:last-child': {
            width: '1px',
            flexGrow: 1
        }
    },
    children: {
        flexGrow: 1,
        '@media(max-width: 768px)': {
            flexBasis: '100%',
            order: 1
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}));

function CrumbsNav({navCrumbs}) {
    if (!navCrumbs || navCrumbs.length == 0) { return null; }

    return (
        <Breadcrumbs>
            { navCrumbs.map((c, i) =>
                    c.to ? <Link key={i} to={c.to}>{c.text}</Link>
                         : <Typography key={i} color="textPrimary">{c.text}</Typography>)
            }
        </Breadcrumbs>
    );
}

export default function PageHeadingBar({children, action, navCrumbs, enableClose}) {
    const classes = useStyles();

    const showCloseButton = enableClose && navCrumbs && navCrumbs.length >= 2;
    const closeTo = showCloseButton ? navCrumbs[navCrumbs.length-2].to : null;

    return (
        <div className={classes.headingBanner}>
            <div>
                <CrumbsNav navCrumbs={navCrumbs} />
            </div>
            <div className={classes.children}>{children}</div>
            <div className={classes.actions}>
                {action}
                {showCloseButton && 
                    <IconButton tabIndex="99999" component={Link} to={closeTo}>
                        <CloseIcon />
                    </IconButton>
                }
            </div>
        </div>
    );
}

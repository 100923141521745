import React from 'react';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useDeleteButtonStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative'
    },
    spinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-20px',
        marginTop: '-20px'
    },
    tooltipPlacementBottom: {
        marginTop: theme.spacing(1)
    }
}));

export default function DeleteIconButton({onClick, inProgress}) {
    const classes = useDeleteButtonStyles();

    if (inProgress) {
        return (
            <span className={classes.wrapper}>
                <IconButton color="secondary" disabled>
                    <DeleteIcon />
                </IconButton>
                <CircularProgress color="secondary" className={classes.spinner} />
            </span>
        );
    }

    return (
        <Tooltip title="Delete" classes={{tooltipPlacementBottom: classes.tooltipPlacementBottom}}>
            <IconButton color="secondary" onClick={onClick}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );
}

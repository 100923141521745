import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import SpinnerButton from '../../components/SpinnerButton';
import SuccessBanner from '../../components/SuccessBanner';
import ErrorBannder from '../../components/ErrorBanner';

import useAsyncCallback from '../../hooks/useAsyncCallback';


const useStyles = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    notification: {
        margin: theme.spacing(4)
    }
}));


export default function ForgotPasswordDialog({open, onClose, onSendResetEmail}) {
    const classes = useStyles();

    const [sendResetEmail, {isPending, isSuccess, isFinished, errorMsg}, clearStatus] = useAsyncCallback(onSendResetEmail);

    const [email, setEmail] = React.useState('');

    const handleClose = () => {
        if (!isPending) {
            setEmail('');
            clearStatus();
            onClose();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        sendResetEmail(email);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
            <DialogTitle>Password Reset</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Typography>We'll send you a password reset email:</Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        type="email"
                        autoFocus
                        helperText="ENTER THE EMAIL ADDRESS YOU REGISTERED WITH"
                        disabled={isPending}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />

                    {isSuccess &&
                        <SuccessBanner
                            className={classes.notification}
                            text="Email Sent! Check your Inbox."/>
                    }

                    <ErrorBannder
                        className={classes.notification}
                        text={errorMsg}/>
                    
                    <SpinnerButton
                        fullWidth
                        variant={isFinished ? 'outlined' : 'contained'}
                        color="secondary"
                        type='submit'
                        pending={isPending}
                        className={classes.submit}
                    >
                        {isPending ? 'Emailing...' : isFinished ? 'Re-Send Email' : 'Email me a reset link'}
                    </SpinnerButton>

                    {!isPending &&
                        <Button
                            fullWidth
                            color={isFinished ? 'primary' : 'default'}
                            variant={isFinished ? 'contained' : 'outlined'}
                            onClick={handleClose}
                        >
                            {isFinished ? 'Close' : 'Cancel'}
                        </Button>
                    }
                </form>
            </DialogContent>
        </Dialog>
    );
}

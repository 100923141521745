import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import SpinnerButton from '../../components/SpinnerButton';
import ErrorBanner from '../../components/ErrorBanner';

import useActions from '../../hooks/useActions';
import useAsyncCallback from '../../hooks/useAsyncCallback';


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();

    const actions = useActions();
    const [userSignUp, {isPending, errorMsg}] = useAsyncCallback(actions.userSignUp);

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [acceptsMarketing, setAcceptsMarketing] = React.useState(false);

    const signUp = (event) => {
        event.preventDefault();
        userSignUp(name, email, password, acceptsMarketing);
    };

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <Avatar className={classes.avatar}>
                <PersonAddIcon />
            </Avatar>
            <Typography component="h1" variant="h6">Create Your Account</Typography>
            <form className={classes.form} onSubmit={signUp}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="Name"
                    name="firstName"
                    autoComplete="fname"
                    disabled={isPending}
                    autoFocus
                    onChange={event => setName(event.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    type="email"
                    disabled={isPending}
                    onChange={event => setEmail(event.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    disabled={isPending}
                    onChange={event => setPassword(event.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            disabled={isPending}
                            onChange={event => setAcceptsMarketing(event.target.checked)}
                        />
                    }
                    label="I am willing to receive the occasional promotional email."
                />
                <SpinnerButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    pending={isPending}
                    className={classes.submit}
                >
                    Sign Up
                </SpinnerButton>
                <ErrorBanner text={errorMsg} />
                <Typography variant="subtitle2" color="textSecondary" align="right">Already have an account? <Link component={RouterLink} to="/signin">Log in.</Link></Typography>
            </form>
        </Container>
    );
}

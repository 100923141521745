import { useEffect } from 'react';
import useAsyncCallback from './useAsyncCallback';
import {getUserID as auth_getUserID} from '../api/auth';

export default function useUserID() {
    const [getUserID, {isPending, result}] = useAsyncCallback(auth_getUserID);

    // load on first mount
    useEffect(() => {
        getUserID();
    }, []);

    return {
        userId: result,
        isPending
    };
}
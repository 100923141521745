import { types, isPending, isSuccess, isFailure } from '../actions';

const initialState = {
    isSubscribed: null
};

export default function mailinglist(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_IS_MAILING_LIST_SUBSCRIBED:
            if (isPending(action)) {
               return initialState;
            }
            if (isSuccess(action)) {
                return { isSubscribed: action.payload.isSubscribed };
            }
            break;
        case types.CHANGE_IS_MAILING_LIST_SUBSCRIBED:
            if (isSuccess(action)) {
                return { isSubscribed: action.payload.isSubscribed };
            }
            break;
    }

    return state;
}
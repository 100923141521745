import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useSubscriptionDetails() {
    const [loadSubscriptionPlan, {isPending, errorMsg}] = useAsyncCallback(actions.loadSubscriptionPlan());
    const subscription = useSelector(state => state.subscription);
    const {isOnPaymentPlan, trialExpired, trialExpiresAt} = useSelector(state => state.setupstatus);

    const needToLoadDetails = isOnPaymentPlan && !subscription.paddlePlanID;

    // load on first mount
    const dispatch = useDispatch();
    useEffect(() => {
        if (needToLoadDetails) {
            dispatch(loadSubscriptionPlan);
        }
    }, [needToLoadDetails]);

    return [
        subscription,
        {isOnTrial: !isPending && !isOnPaymentPlan && !trialExpired, trialExpiresAt},
        isPending,
        errorMsg
    ];
}
import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export default function CopyToClipboardTextField({copyActionText='Copy to Clipboard', onClickCopy, ...props}) {
    const textFieldRef = React.useRef();

    const copyToClipboard = () => {
        textFieldRef.current.select();
        document.execCommand("copy");
        textFieldRef.current.blur();
        onClickCopy && onClickCopy(true);
    }

    return (
        <TextField
            inputRef={textFieldRef}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button color="primary" onClick={copyToClipboard} startIcon={<FileCopyOutlinedIcon />}>
                            {copyActionText}
                        </Button>   
                    </InputAdornment>
                )
            }}
            {...props}
        />
    );
}




import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

export default function TagFilter({tags, onChange, ...props}) {
    const options = tags;

    return (
     <Autocomplete
        {...props}
        multiple
        size="small"
        disableCloseOnSelect
        options={options}
        onChange={(event, newValue) => {
            onChange(newValue);
        }}
        renderOption={(option, { selected }) => (
            <>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </>
          )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Filter (By Tags)"
          />
        )}
      />
    );
}
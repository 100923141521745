import useAsyncCallback from './useAsyncCallback';
import useActions from './useActions';
import useSurvey from './useSurvey';

export default function useShareURL(surveyId) {
    const actions = useActions();
    const [shareSurvey, {isPending:isPendingCreate, errorMsg:errorMsgCreate}] = useAsyncCallback(actions.shareSurvey);
    const [unshareSurvey, {isPending:isPendingDelete, errorMsg:errorMsgDelete}] = useAsyncCallback(actions.unshareSurvey);
    const [survey, isLoadingSurvey, errorMsgSurvey] = useSurvey(surveyId);
    
    const enableSharing = () => { shareSurvey(surveyId); }
    const disableSharing = () => { unshareSurvey(surveyId); }

    const shareURL = window.location.protocol + '//' + window.location.host + '/share/' + surveyId;

    return {
        isLoading: isLoadingSurvey || isPendingCreate || isPendingDelete,
        shareURL: survey && survey.isShared ? shareURL : null,
        enableSharing,
        disableSharing,
        errorMsg: errorMsgSurvey || errorMsgCreate || errorMsgDelete
    };
}
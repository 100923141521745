import React from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';


const useStyles = makeStyles(theme => ({
    text: {
        display: 'inline-block',
        width: '4.5em'
    }
}));


export default function DateRangePaginator({timestamp, timeUnit, onChange}) {
    const classes = useStyles();

    // basis keeps track of a real stable point-in-time of this component.
    // This allows the timestamp prop to relocate to the start of the timeUnit range
    // without causing us to drift through time if/when the timeUnit toggles between
    // week and month (whose startOf's don't align).
    const [basis, setBasis] = React.useState(timestamp);

    const startDate = moment(basis).startOf(timeUnit);
    const endDate = moment(startDate).endOf(timeUnit);
    const disableNext = endDate.isSameOrAfter();

    // keep basis roughly in-sync with the prop,
    // as long as the prop is somewhere in the timerange then our basis is good
    // otherwise we've moved to a different week/month/whatever and that's our new basis
    React.useEffect(() => {
        if (!moment(timestamp).isBetween(startDate, endDate, undefined, '[]')) {
            setBasis(timestamp);
        }
    }, [timestamp]);

    // dispatch a change event if our timestamp is a mid-period date (not aligned to start)
    // this happens when swithing time period
    React.useEffect(() => {
        if (onChange && timestamp !== startDate.valueOf()) {
            onChange(startDate.valueOf());
        }
    }, [timeUnit]);


    const clickPrev = () => {
        onChange(moment(startDate).subtract(1, timeUnit).valueOf());
    }

    const clickNext = () => {
        onChange(moment(startDate).add(1, timeUnit).valueOf());
    }
    
    return (<span>
        <IconButton onClick={clickPrev}><ChevronLeftIcon/></IconButton>
        <Typography className={classes.text} align="right" variant="overline">{startDate.format('MMM DD')}</Typography>
        <Typography align="center" variant="overline"> - </Typography>
        <Typography className={classes.text} align="left" variant="overline">{endDate.format('MMM DD')}</Typography>
        <IconButton disabled={disableNext} onClick={clickNext}><ChevronRightIcon/></IconButton>
    </span>);
}

import { types, isPending, isSuccess, isFailure } from '../actions';

const initialState = {
    needsSlackConfiguring: false,
    needsChannelInvite: false,
    trialExpired: false,
    trialExpiresAt: 0,
    isOnPaymentPlan: false
};

export default function setupstatus(state = initialState, action) {
    switch (action.type) {
        case types.USER_CHANGE_INFO:
            if (isSuccess(action)) {
                return {
                    ...state,
                    trialExpired: action.payload.isTrialExpired !== undefined ? action.payload.isTrialExpired : state.trialExpired,
                    trialExpiresAt: action.payload.trialExpiresAt !== undefined ? action.payload.trialExpiresAt : state.trialExpiresAt,
                    isOnPaymentPlan: action.payload.paddleSubscriptionID !== undefined ? !!action.payload.paddleSubscriptionID : state.isOnPaymentPlan
                };
            }
            break;
        case types.CHANGE_SUBSCRIPTION_PLAN:
        case types.START_SUBSCRIPTION_PLAN:
            if (isSuccess(action)) {
                return { ...state, isOnPaymentPlan: true };
            }
            break;
        case types.CANCEL_SUBSCRIPTION_PLAN:
            if (isSuccess(action)) {
                return { ...state, isOnPaymentPlan: false };
            }
            break;
        case types.LOAD_INTEGRATIONS:
            if (isSuccess(action)) {
                return {
                    ...state,
                    needsSlackConfiguring: action.payload.slackIntegrations.length === 0,
                    needsChannelInvite: !action.payload.slackIntegrations.some(({channels}) => channels.length > 0)
                };
            }
            break;
    }

    return state;
}
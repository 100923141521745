import { types, isPending, isSuccess, isFailure } from '../actions';

const initialState = {
    slackIntegrations: [],
    isLoading: true,
    errorMsg: null
};

export default function integrations(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_INTEGRATIONS:
            if (isPending(action)) {
               return {
                   ...state,
                   isLoading: true
               };
            }
            if (isSuccess(action)) {
                return {
                    ...state,
                    slackIntegrations: action.payload.slackIntegrations,
                    isLoading: false,
                    errorMsg: null
                };
            }
            if (isFailure(action)) {
                return {
                    ...state,
                    errorMsg: action.error.message
                };
            }
            break;
        case types.REMOVE_INTEGRATION:
            if (isPending(action)) {
                return {
                    ...state,
                    slackIntegrations: state.slackIntegrations.map(it => (it.id === action.payload.id ? {...it, isDeleting: true} : it))
                };
            }
            if (isSuccess(action)) {
                return {
                    ...state,
                    slackIntegrations: state.slackIntegrations.filter(it => it.id !== action.payload.id)
                };
            }
            break;
    }

    return state;
}
import { types, isPending, isSuccess } from '../actions';

const initialState = {
    all: [],
    shared: null
};

function surveyList(state = [], action) {
    switch (action.type) {
        case types.LOAD_SURVEYS:
            if (isSuccess(action)) {
                return action.payload.surveys;
            }
            break;
        case types.DELETE_SURVEY:
            if (isPending(action)) {
                return state.map(s => (s.id === action.payload.id ? {...s, isDeleting: true} : s));
            }
            if (isSuccess(action)) {
                return state.filter(s => s.id !== action.payload.id);
            }
            break;
        case types.UPDATE_SURVEY_TAGS:
            if (isSuccess(action)) {
                return state.map(s => (s.id === action.payload.id ? {...s, config: {...s.config, tags: action.payload.tags}} : s));
            }
            break;
        case types.SHARE_SURVEY:
            if (isSuccess(action)) {
                return state.map(s => (s.id === action.payload.id ? {...s, isShared: true} : s));
            }
            break;
        case types.UNSHARE_SURVEY:
            if (isSuccess(action)) {
                return state.map(s => (s.id === action.payload.id ? {...s, isShared: false} : s));
            }
            break;
    }

    return state;
}

function sharedSurvey(state = null, action) {
    switch (action.type) {
        case types.LOAD_SHARED_SURVEY:
            if (isSuccess(action)) {
                return action.payload.sharedSurvey;
            }
            break;
    }

    return state;
}

export default function surveys(state = initialState, action) {
    return {
        all: surveyList(state.all, action),
        shared: sharedSurvey(state.shared, action)
    };
}

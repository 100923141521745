import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import getStartedImg from './undraw_good_team.svg';


const useNoResultsStyles = makeStyles(theme => ({
    box: {
        width: '500px',
        textAlign: 'center',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    getStarted: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

export default function NoSurveysYet() {
    const classes = useNoResultsStyles();

    return (
        <div elevation={0} className={classes.box}>
            <img src={getStartedImg} width="500px"/>
            <Typography variant="h4" className={classes.getStarted}>Nothing To Show Here Yet</Typography>
            <Typography variant="h5" className={classes.getStarted}>Get Started...</Typography>
            <Fab variant="extended" color="primary" component={Link} to="/add">
                <AddIcon/>
                Create A Survey
            </Fab>
        </div>
    );
}

import React from 'react';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/StarBorder';
import CheckIcon from '@material-ui/icons/Check';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';

import SpinnerButton from '../SpinnerButton';


const usePriceCardStyles = makeStyles(theme => ({
    card: {
        padding: '20px'
    },
    headerSection: {
        padding: '20px'
    },
    perksSection: {
        padding: '20px',
        height: '15em'
    },
    subscriptionName: {
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '1.3em',

        textAlign: 'center'
    },
    blurb: {
        color: theme.palette.text.secondary,
        fontSize: '1em',
        textAlign: 'center'
    },
    price: {
        fontSize: '2.5em',
        lineHeight: '1em',
        textAlign: 'center',
        display: 'block',
        marginTop: '0.5em'
    },
    perPeriod: {
        display: 'block',
        textAlign: 'center',
    },
    billingFreq: {
        textAlign: 'center',
        fontSize: '0.8em',
        marginBottom: '1em',
        color: theme.palette.text.secondary,
    },
    icon: {
        display: 'block',
        margin: 'auto',
        marginBottom: '10px',
        height: '70px',
        width: '70px'
    },
    planLoading: {
        cursor: 'defualt',
        pointerEvents: 'none',
        backgroundColor: blue[50]
    },
    currentPlan: {
        cursor: 'defualt',
        pointerEvents: 'none',
        backgroundColor: green[400],
        color: 'white'
    }
}));


function BuyButton({isLoading, isDowngrade, isUpgrade, isActive, onClick}) {
    const classes = usePriceCardStyles();

    if (isLoading) {
        return <SpinnerButton color="primary" className={classes.planLoading} fullWidth pending>Loading...</SpinnerButton>
    }

    if (isActive) {
        return <Button color="primary" className={classes.currentPlan} fullWidth>Current Plan <StarIcon /></Button>
    }

    if (isDowngrade) {
        return <Button variant="outlined" color="secondary" fullWidth onClick={onClick}>Select Plan</Button>
    }

    return <Button variant="contained" color="primary" fullWidth onClick={onClick}>
        {isUpgrade ? 'Upgrade Now' : 'Buy Now'}
    </Button>
}

export default function PriceCard({icon, title, blurb, price, perPeriod, billingFrequency, perks, isLoading, isDowngrade, isUpgrade, isActive, onBuy, onChangePlan}) {
    const classes = usePriceCardStyles();

    return (
        <Card variant="outlined">
            <BuyButton
                isLoading={isLoading}
                isDowngrade={isDowngrade}
                isUpgrade={isUpgrade}
                isActive={isActive}
                onClick={isDowngrade || isUpgrade ? onChangePlan : onBuy}
            />

            <div className={classes.headerSection}>
                <img src={icon} className={classes.icon} />
                <Typography variant="h5" className={classes.subscriptionName}>{title}</Typography>
                <Typography variant="overline" className={classes.price}>{price}</Typography>
                <Typography variant="overline" className={classes.perPeriod}>{perPeriod}</Typography>
                <Typography variant="body1" className={classes.billingFreq}>{billingFrequency}</Typography>
                <Typography className={classes.blurb}>{blurb}</Typography>
            </div>
            
            
            <Divider variant="middle" />

            <div className={classes.perksSection}>
                {perks.map((p, idx) => <Typography key={idx} variant="body2"><CheckIcon color="primary" fontSize="small" /> {p}</Typography>)}
            </div>

            <BuyButton
                isLoading={isLoading}
                isDowngrade={isDowngrade}
                isUpgrade={isUpgrade}
                isActive={isActive}
                onClick={isDowngrade || isUpgrade ? onChangePlan : onBuy}
            />
        </Card>
    );
}

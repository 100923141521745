import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useMailingListStatus() {
    const [loadMailingListSubscribedStatus, {errorMsg}] = useAsyncCallback(actions.loadMailingListSubscribedStatus());
    const isSubscribed = useSelector(state => state.mailinglist.isSubscribed);

    // load on first mount
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadMailingListSubscribedStatus);
    }, []);

    return [
        isSubscribed,
        errorMsg
    ];
}
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ShareIcon from '@material-ui/icons/Share';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';

import CopyToClipboardTextField from '../../components/CopyToClipboardTextField';

import useShareURL from '../../hooks/useShareURL';


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'right',
        width: '40em'
    },
    backdrop: {
        zIndex: theme.zIndex.modal - 1
    }
}));


export default function ShareButton({surveyId, btnRef}) {
    const classes = useStyles();

    const {isLoading, errorMsg, shareURL, enableSharing, disableSharing} = useShareURL(surveyId);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isCopied, setCopied] = React.useState(false);
    const [allowAutoClose, setAllowAutoClose] = React.useState(true);

    // state for the toggle, kept in sync with the redux store
    const [isSharingEnabled, setSharingEnabled] = React.useState(false);
    React.useEffect(() => {
        setSharingEnabled(!!shareURL);
    }, [!!shareURL])

    const handleClick = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
            setAllowAutoClose(true);
            setCopied(false);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (event) => {
        if (event.target.checked) {
            enableSharing();
            setSharingEnabled(true);
            setAllowAutoClose(false);
        } else {
            disableSharing();
            setSharingEnabled(false);
            setAllowAutoClose(true);
            setCopied(false);
        }
    };

    return (<>
        <IconButton ref={btnRef} onClick={handleClick} disabled={!surveyId}>
            <ShareIcon />
        </IconButton>
        <Backdrop className={classes.backdrop} open={!!anchorEl} />
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={allowAutoClose && handleClose}
            classes={{
                paper: classes.paper
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div style={{display:'flex', alignItems:'center'}}>
                <ShareIcon  style={{marginRight: '0.3em'}} />
                <Typography variant="h5" align="left">Share These Results</Typography>
            </div>
            
            <Typography variant="subtitle1" align="left">Allow anyone with the link to view this page.</Typography>
            <FormControlLabel
                control={
                    <Switch
                        checked={isSharingEnabled}
                        disabled={isLoading}
                        onChange={handleToggle}
                        color="primary"
                    />
                }
                label={shareURL ? 'Sharing Enabled:' : 'Click to Enable Sharing:'}
                labelPlacement="start"
            />
            {shareURL && <CopyToClipboardTextField
                margin="normal"
                variant="outlined"
                fullWidth
                autoFocus
                label="Share Link"
                copyActionText="Copy Link"
                onClickCopy={setCopied}
                value={shareURL}
                disabled={isLoading}
            />}
            <Typography>{errorMsg}</Typography>
            {(!allowAutoClose && shareURL || isCopied) && <Button color="secondary" endIcon={<NavigateNextIcon />} onClick={handleClose} >
                Done
            </Button>}
        </Popover>
    </>);
}

import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as ReactRouterLink } from 'react-router-dom';


export default function SimpleMenuButton({items, btnElement, children, ...props}) {
    const ButtonElement = btnElement; // must start with caps for jsx

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const createMenuClickHandler = (onClick) => () => {
        handleClose();
        if (onClick) { onClick(); }
    };

    return (
        <>
            <ButtonElement {...props} onClick={handleOpen}>
                {children}
            </ButtonElement>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                >
                
                {items.map(item => {
                    const routerLinkProps = item.linkTo ? { component:ReactRouterLink, to:item.linkTo } : {};
                    return (
                        <MenuItem key={item.key || item.text} onClick={createMenuClickHandler(item.onClick)} {...routerLinkProps}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    )
}

export function validatePasswordPair(password1, password2) {
    const validity1 = validatePassword(password1);
    const validity2 = validatePassword(password2);
    const mismatch = password1 !== password2;
    const allValid = validity1.valid && validity2.valid && !mismatch;

    return {
        allValid, 
        password1: {
            valid: validity1.valid,
            msg: validity1.msg
        },
        password2: {
            valid: validity2.valid && !mismatch,
            msg: validity2.msg || (mismatch ? 'Both passwords must match' : null)
        }
    };
}

export function validatePassword(password) {
    const tests = {
        tooShort: [!password || password.length < 8, 'Requires 8 or more characters (with capitals & numbers)'],
        missingNumber: [!password.match(/[0-9]/g), 'Requires a number'],
        missingUppercase: [!password.match(/[A-Z]/g), 'Requires a capital letter'],
        missingLowercase: [!password.match(/[a-z]/g), 'Requires a lowercase letter']
    };

    for (const [value, msg] of Object.values(tests)) { 
        if (value) {
            return {valid: false, msg};
        }
    }

    return {valid: true, msg: null};
}
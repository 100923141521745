import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import notFoundImg from './undraw_feeling_blue.svg';


const useNoResultsStyles = makeStyles(theme => ({
    paper: {
        width: '500px',
        textAlign: 'center',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));

export default function NoResults() {
    const classes = useNoResultsStyles();

    return (
        <Paper elevation={0} className={classes.paper}>
            <img src={notFoundImg} width="150px"/>
            <Typography variant="h5">Nothing To Show</Typography>
            <Typography variant="subtitle1">No users match the current filter criteria</Typography>
        </Paper>
    );
}

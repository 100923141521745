import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 40
    }
}));

export default function PageTitle({children, ...props}) {
    const classes = useStyles();
    return <Typography variant='h4' className={classes.title} {...props}>{children}</Typography>
}
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/PersonOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import Header from '../Header';
import ChangeEmailDialog from './ChangeEmailDialog';
import ChangePasswordDialog from './ChangePasswordDialog';

import useActions from '../../../hooks/useActions';
import useUserInfo from '../../../hooks/useUserInfo';
import useToggle from '../../../hooks/useToggle';

import { resendEmailChangeVerification } from '../../../api/auth';


const useStyles = makeStyles(theme => ({
    section: {
      margin: theme.spacing(5, 0),
    }
}));

export default function Personal() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const actions = useActions();
    const user = useUserInfo();

    const [isEmailDialogOpen, openEmailDialog, closeEmailDialog] = useToggle(false);
    const [isPasswordDialogOpen, openPasswordDialog, closePasswordDialog] = useToggle(false);

    const [newUsername, setNewUsername] = React.useState('');
    const isNameChanged = newUsername !== user.name && newUsername !== '';    

    const handleNameFieldChange = (event) => {
        setNewUsername(event.target.value);
    };

    const handleSaveName = async () => {
        try {
            await actions.userChangeName(newUsername);
            enqueueSnackbar('Name Updated.', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(`Error Updating Name: ${error.message}`, { variant: 'error' });
        }
    };

    const handleSaveEmail = async (newEmail) => {
        try {
            await actions.userChangeEmail(newEmail);
            enqueueSnackbar('Email Updated. Check your Inbox for a verification email.', { variant: 'success' });
            closeEmailDialog();
        } catch (error) {
            enqueueSnackbar(`Error Updating Email: ${error.message}`, { variant: 'error' });
        }
    };

    const handleSavePassword = async (oldPassword, newPassword) => {
        try {
            await actions.userChangePassword(oldPassword, newPassword);
            enqueueSnackbar('Password Updated.', { variant: 'success' });
            closePasswordDialog();
        } catch (error) {
            enqueueSnackbar(`Error Updating Password: ${error.message}`, { variant: 'error' });
        }
    };

    const handleResendVerification = async () => {
        try {
            await resendEmailChangeVerification();
            enqueueSnackbar('Verification Email Sent. Check your Inbox.', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(`Error Sending Verificatin Email: ${error.message}`, { variant: 'error' });
        }
    };

    return (<>
        <Header icon={PersonIcon}>Personal Information</Header>
     
            <form noValidate>
                <div className={classes.section}>
                    <Typography variant="button">Name:</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="name"
                        value={newUsername || user.name}
                        onChange={handleNameFieldChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant={isNameChanged ? 'contained' : 'outlined'} disabled={!isNameChanged} color="primary" onClick={handleSaveName} style={{whiteSpace:'nowrap'}}>
                                        Save
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div className={classes.section}>
                    <Typography variant="button">Email:</Typography>
                    <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        name="email"
                        value={user.email}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant="contained" color="secondary" onClick={openEmailDialog} style={{whiteSpace:'nowrap'}}>
                                        Change Email
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    {!user.isEmailVerified && <>
                        <Chip label="Email address not verified" color="secondary" size="small" />
                        <Button size="small" color="primary" onClick={handleResendVerification}>
                            Re-Send Verification Email
                        </Button>
                    </>}
                    <ChangeEmailDialog open={isEmailDialogOpen} onClose={closeEmailDialog} onSubmit={handleSaveEmail} />
                </div>
                <div className={classes.section}>
                    <Typography variant="button">Password:</Typography>
                    <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        name="password"
                        value="***** Not Shown *****"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant="contained" color="secondary" onClick={openPasswordDialog} style={{whiteSpace:'nowrap'}}>
                                        Change Password
                                    </Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    <ChangePasswordDialog open={isPasswordDialogOpen} onClose={closePasswordDialog} onSubmit={handleSavePassword} />
                </div>
            </form>

    </>);
}
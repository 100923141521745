const actions = [
    // Asynchronous Actions
    'USER_CHANGE_INFO',
    'LOAD_IS_MAILING_LIST_SUBSCRIBED',
    'CHANGE_IS_MAILING_LIST_SUBSCRIBED',
    'LOAD_SUBSCRIPTION_DETAILS',
    'START_SUBSCRIPTION_PLAN',
    'CHANGE_SUBSCRIPTION_PLAN',
    'CANCEL_SUBSCRIPTION_PLAN',
    'LOAD_INTEGRATIONS',
    'REMOVE_INTEGRATION',
    'LOAD_SURVEYS',
    'NEW_SURVEY',
    'EDIT_SURVEY',
    'UPDATE_SURVEY_TAGS',
    'DELETE_SURVEY',
    'SHARE_SURVEY',
    'UNSHARE_SURVEY',
    'LOAD_SHARED_SURVEY',

    // Special action for generally non-redux affecting API calls
    'API_CALL',

    // Synchronous Actions
    'USER_SIGN_OUT',
];

export function isPending(action) {
    return action.pending;
}

export function isSuccess(action) {
    return action.success;
}

export function isFailure(action) {
    return action.failure;
}

// export as as object
export default actions.reduce((acc, item) => { acc[item]=item; return acc; }, {});
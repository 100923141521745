import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CheckIcon from '@material-ui/icons/Check';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Redirect } from 'react-router-dom';

import InvalidURLParamsScreen from '../../components/InvalidURLParamsScreen';

import useActions from '../../hooks/useActions';
import useUserID from '../../hooks/useUserID';
import useAsyncCallback from '../../hooks/useAsyncCallback';

import parseQueryParams from '../../common/parsequeryparams';


const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    successAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.success.main,
    },
    errorAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
    },
    pendingAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.light
    },
    header: {
        marginBottom: theme.spacing(2)
    },
    spinner: {
        marginTop: theme.spacing(1)
    },
    errorText: {
        fontWeight: 'bold',
        color: theme.palette.error.main,
        fontSize: '1.5em',
        marginBottom: theme.spacing(5)
    },
    button: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(1),
        width: '50%'
    }
}));

function LoadingContent() {
    const classes = useStyles();

    return (<>
        <Avatar className={classes.pendingAvatar}>
            <HourglassEmptyIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.header}>Connecting to Slack</Typography>
        <CircularProgress disableShrink className={classes.spinner}/>
        <Typography>Please Wait...</Typography>
    </>);
}

function FailureContent({msg}) {
    const classes = useStyles();

    return (<>
        <Avatar className={classes.errorAvatar}>
            <PriorityHighIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.header}>An Error Occurred</Typography>
        <Typography>Whoops we were not able to install our Slackbot into your Slack Workspace:</Typography>
        <Typography className={classes.errorText}>{msg}</Typography>
        <Typography>Refresh this page to retry and if that doesn't work you can try the install again from the start.</Typography>
        <Typography>If you need help just contact us.</Typography>
    </>);
}

export default function ConfirmScreen() {
    const classes = useStyles();

    const actions = useActions();
    const [slackOAuthTokenExchange, {isSuccess:tokenExchangeSuccess, errorMsg:tokenExchangeErrorMsg}] = useAsyncCallback(actions.slackOAuthTokenExchange);
    const {userId, isPending:isUserIdPending} = useUserID();

    // extract query params from URL
    // these must match the args given to us by Slack: https://api.slack.com/authentication/oauth-v2
    const {code, state} = parseQueryParams();

    // on-mount effect to confirm the user
    React.useEffect(() => {
        if (code) {
            slackOAuthTokenExchange(code);
        }
    }, [code]);

    if (!code || !state) {
        return <InvalidURLParamsScreen/>;
    }

    // validate state
    let isStateValid = false;
    let stateCheckErrorMsg = null;
    let nextPage = null;
    try {
        if (!isUserIdPending) {
            const [givenUserId, givenNextPage] = JSON.parse(decodeURIComponent(state));
            nextPage = givenNextPage ? encodeURIComponent(givenNextPage) : null;
            isStateValid = givenUserId === userId; // must match
            if (!isStateValid) {
                stateCheckErrorMsg = 'Your userId is not what we expected. Please try again from the start.';
            }
        }
    }
    catch (err) {
       console.log(err);
       stateCheckErrorMsg = 'An error occurred verifying the state: ' + (err.message || 'Please try again from the start.');
    }

        
    let isSuccess = tokenExchangeSuccess && isStateValid;
    let errorMsg = tokenExchangeErrorMsg || stateCheckErrorMsg;

    return (
        <Container className={classes.container} maxWidth="sm">
            {isSuccess ? <Redirect to={'/welcome/slack?success' + (nextPage ? '&next=' + nextPage : '')} />
            : errorMsg ? <FailureContent msg={errorMsg}/>
            : <LoadingContent/>}
        </Container>
    );
}

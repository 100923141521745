import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'
import { makeStyles } from '@material-ui/core/styles';

const transitionDuration = 200;

const enterAndAppearInitial = {
    opacity: '0',
    transform: 'translate(-30px, 0)'
};

const enterAndAppearActive = {
    opacity: '1',
    transform: 'translate(0, 0)',
    transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`
};

const useStyles = makeStyles(theme => ({
    pageWrap: {
        position: 'relative' // allows page to overlap as they transition
    },
    page: {
        position: 'absolute', // allows page to overlap as they transition
        width: '100%',
    },
    enter: enterAndAppearInitial,
    appear: enterAndAppearInitial,
    exit: {},
    enterActive: enterAndAppearActive,
    appearActive: enterAndAppearActive,
    exitActive: {
        opacity: '0',
        transform: 'translate(30px, 0)',
        transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`
    },
    enterDone: {},
    appearDone: {},
    exitDone: {}
}));

export function TransitionRoute({children, classes, transitionTimeout, ...routeProps}) {
    const childrenFn = ({match}) => {
        return <CSSTransition
            in={match !== null}
            timeout={transitionDuration}
            classNames={classes}
            unmountOnExit
        >
            <div className={classes.page}>
                {children}
            </div>
        </CSSTransition>
    };

    return <Route exact {...routeProps} children={childrenFn} />;
}

export function TransitionRouteSet({routes, defaultRouteChild}) {
    const classes = useStyles();

    return (<div className={classes.pageWrap}>
        {routes.map(r => (
            <TransitionRoute key={r.path} path={r.path} classes={classes} transitionTimeout={transitionDuration}>
                <r.Component/>
            </TransitionRoute>
        ))}

        {/* conditional default for when no paths match (typically a Redirect) */}
        <Switch>
            <Route exact path={routes.map(r => r.path)} />
            <Route>
                {defaultRouteChild}
            </Route>
        </Switch>
    </div>);
}
import React from 'react';
import Typography from '@material-ui/core/Typography';

import CopyToClipboardTextField from './CopyToClipboardTextField';

export default function InviteToChannelInstruction({callToActionText}) {
    return (<>
        <Typography variant="body1">{callToActionText}</Typography>
        <CopyToClipboardTextField
            margin="normal"
            variant="outlined"
            fullWidth
            autoFocus
            label="➕ Invite Command"
            value="/invite @Team Feels"
        />
        <Typography variant="body1">Invite to as many channels as needed!</Typography>
    </>);
}
import React from 'react';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../Header';

import AddToSlackButton from '../../../components/AddToSlackButton';
import DeleteIconButton from '../../../components/DeleteIconButton';
import InviteToChannelInstruction from '../../../components/InviteToChannelInstruction';

import useActions from '../../../hooks/useActions';
import useIntegrations from '../../../hooks/useIntegrations';
import useToggle from '../../../hooks/useToggle';

function AddChannelsDialog({open, onClose}) {
    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle>Invite our bot</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <InviteToChannelInstruction callToActionText="Paste this into any Slack channels that you want to use:" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    alert: {
        marginBottom: theme.spacing(1)
    },
    addBar: {
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    integrationItem: {
        marginBottom: theme.spacing(2)
    },
    integrationHeader: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            fontWeight: 'bold'
        }
    },
    deleteButton: {
        marginLeft: 'auto'
    },
    workspacesAndChannels: {
        margin: theme.spacing(1),
        '& > *': {
            margin: 0
        }
    },
    listContainer: {
        position: 'relative',
        minHeight: '100px'
    },
    backdrop: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.drawer + 1,
        
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function IntegrationsList({slackIntegrations, isLoading}) {
    const classes = useStyles();
    const {removeIntegration, loadIntegrations} = useActions();

    const [isAddChannelDialogOpen, openAddChannelDialog, closeAddChannelDialog] = useToggle(false);

    const onCloseChannelDialog = () => {
        closeAddChannelDialog();
        loadIntegrations();
    }

    return (<div className={classes.listContainer}>
        {isLoading &&
            <div className={classes.backdrop}>
                <CircularProgress className={classes.progress}/>
            </div>
        }
        {slackIntegrations.map(({id, workspaces, channels, isEnterprise, isDeleting}) => 
        <Paper key={id} variant="outlined" className={classes.integrationItem}>
            <div className={classes.integrationHeader}>
                <img src="https://cdn.brandfolder.io/5H442O3W/as/pl546j-7le8zk-199wkt/Slack_Mark.svg" width="64" height="64" />
                
                {isEnterprise && <Typography variant="overline">Slack (Enterprise)</Typography>}
                {!isEnterprise && 
                    <Typography variant="body1">
                        <Typography variant="overline">Slack Workspace: </Typography>
                        
                        <a href={'https://app.slack.com/client/' + workspaces[0].id}>
                            <b>{workspaces[0].name}</b>
                        </a>
                    </Typography>
                }
                
                <span className={classes.deleteButton}><DeleteIconButton inProgress={isDeleting} onClick={() => removeIntegration(id)} /></span>
            </div>

            <div className={classes.workspacesAndChannels}>
                {isEnterprise &&
                    <Typography variant="body1">
                        <Typography variant="overline">Workspaces: </Typography>
                        {workspaces.map((workspace, idx) =>
                            <React.Fragment key={workspace.id}>
                                {idx > 0 ? ', ' : ''}
                                <a href={'https://app.slack.com/client/' + workspace.id}>
                                    <b>{workspace.name}</b>
                                </a>
                            </React.Fragment>
                        )}
                    </Typography>
                }
                
                <Typography variant="body1">
                    <Typography variant="overline">Channels: </Typography>
                    {channels.map((channel, idx) =>
                        <React.Fragment key={channel.id}>
                            {idx > 0 ? ', ' : ''}
                            <a href={`https://app.slack.com/client/${id}/${channel.id}`}>
                                {channel.name}
                            </a>
                        </React.Fragment>
                    )}
                    {channels.length === 0 && 'None - Not invited to any'}
                </Typography>

                <Button color="primary" onClick={openAddChannelDialog}>+ Add Channel</Button>
            </div>

            <AddChannelsDialog open={isAddChannelDialogOpen} onClose={onCloseChannelDialog} />
        </Paper>)}
    </div>);
}

export default function Integrations() {
    const classes = useStyles();

    const [enabledSlackIntegrations, disabledSlackIntegrations, isPending, errorMsg, canAddMore] = useIntegrations();
 
    return (<>
        <Header icon={CategoryIcon}>Manage Integrations</Header>

        <Alert severity="info" className={classes.alert}>
            <Typography variant="body2">Add and remove available Slack App integrations and review the workspaces that our app can post to.</Typography>
        </Alert>

        <div className={classes.addBar}>
            <Typography variant="body2">Click to add a new Slack Workspace or Organisation:</Typography>
            <Tooltip title={!canAddMore ? 'Upgrade your plan to add more!' : 'Add another Slack team (workspace or organisation)'} placement="right">
                {/* below <div> is needed for proper <Tooltip> functioning, not sure why */}
                <div><AddToSlackButton nextPage='account' disabled={!canAddMore} /></div>
            </Tooltip>
            {!canAddMore &&
                <Typography variant="body1"><Link to="/account/subscription"><strong>Want to add multiple teams?</strong> Upgrade your plan today!</Link></Typography>
            }
        </div>

        <Typography variant="h6">Active Integrations</Typography>
        {enabledSlackIntegrations.length === 0 && !isPending && <Typography variant="body1">There are no integrations currently setup.</Typography>}

        <IntegrationsList slackIntegrations={enabledSlackIntegrations} isLoading={isPending} />

        {disabledSlackIntegrations.length > 0 && <>
            <Typography variant="h6">Inactive Integrations</Typography>
            <Typography variant="body1"><Link to="/account/subscription"><strong>Enable multiple teams:</strong> Upgrade your plan today!</Link></Typography>
            <Typography variant="body1">Your subscription plan is for <strong>1 team</strong>.</Typography>
            <Typography variant="body1">The following integrations have been previously configured but are currently unavailable on your plan:</Typography>
            <IntegrationsList slackIntegrations={disabledSlackIntegrations} isLoading={isPending} />
        </>}        
    </>);
}
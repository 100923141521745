import { types, isPending, isSuccess, isFailure } from '../actions';

const initialState = {
    userInfoLoaded: false,
    isSignInRequired: false,
    name: 'No User Initialized',
    email: 'No User Initialized',
    isEmailVerified: true, // true prevents 'email not verified' flickering during loading on /account page
};

export default function userInfo(state = initialState, action) {
     // handle any session expiration error from any & all actions
     if (action.error && (action.error.sessionExpired || !state.userInfoLoaded)) {
        return {
            ...initialState,
            isSignInRequired: true
        };
    }

    switch (action.type) {
        case types.USER_CHANGE_INFO:
            if (isPending(action) && !state.userInfoLoaded) {
                // this is only true once whilst page loading
                return {
                    ...initialState,
                    name: 'Loading...',
                    email: 'Loading...'
                };
            }
            if (isSuccess(action)) {
                return {
                    ...state,
                    userInfoLoaded: true,
                    name: action.payload.name || state.name,
                    email: action.payload.email || state.email,
                    isEmailVerified: action.payload.isEmailVerified == null ? state.isEmailVerified : action.payload.isEmailVerified
                };
            }
            break;

        case types.USER_SIGN_OUT:
            return {
                ...initialState,
                isSignInRequired: true
            };
            break;
    }

    return state;
}
import React from 'react';
import { Link, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import { CSSTransition } from 'react-transition-group'
import { makeStyles } from '@material-ui/core/styles';

import { timeSince, timeUntil, timeOfDay, dayOfWeek, shortToLongDayOFWeek } from '../../common/formattime';

import DeleteIconButton from '../../components/DeleteIconButton';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';

import EditTagsDialog from './EditTagsDialog';

import useToggle from '../../hooks/useToggle';

const pulseAnimationOpacity = '0.8';
const transitionDurationMillis = 300;
const gapBetweenStartAndEndColumns = '80px';
const maxHeight = '200px'; // must be >= to the calculated height of the survey widget
const padding = '16px';
const margin = '10px';

const useStyles = makeStyles(theme => ({
    '@keyframes pulse': {
        '0%': { opacity: pulseAnimationOpacity },
        '50%': { opacity: '0.3' },
        '100%': { opacity: pulseAnimationOpacity }
    },
    surveyItem: {
        display: 'block',
        textDecoration: 'none',
        marginBottom: margin,
        padding: padding,
        transition: 'transform 200ms, box-shadow 200ms',
        '&.deleting': {
            animation: '$pulse 2s infinite ease-in-out'
        },
        '&:hover': {
            border: 'solid 1px #ddf1d4',
            cursor: 'pointer',
            '& h6': {
                color: 'blue',
                textDecoration: 'underline'
            }
        },
        boxShadow: '0 -10px 15px #ffffff, 0 6px 20px #eaecff',
        border: 'solid 1px #eff1fb'
    },
    columnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginLeft: '-' + gapBetweenStartAndEndColumns
    },
    startColumns: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: '9999999',
        marginLeft: gapBetweenStartAndEndColumns
    },
    endColumns: {
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: '1',
        marginLeft: gapBetweenStartAndEndColumns
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& > *:first-child': {
            lineHeight: '1.5rem'
        }
    },
    firstColumn: {
        flexGrow: '1'
    },
    recurrenceColumn: {
        textAlign: 'right'
    },
    tagsColumn: {
        width: '15em',
        borderRadius: '2px',
        '&:hover': {
            boxShadow: '0 0 20px rgba(0,0,0,0.08) inset'
        },
        '& *': {
            cursor: 'pointer'
        }
    },
    actionButtonsColumn: {
        whiteSpace: 'nowrap'
    },
    tagList: {
        '& > *': {
            margin: theme.spacing(0.2)
        }
    },
    responseCountChip: {
        borderColor: theme.palette.tertiary.light,
        color: theme.palette.tertiary.main
    },
    nextDueChip: {
        '& .MuiChip-label': {
            whiteSpace: 'normal',
            '& span': {
                whiteSpace: 'nowrap'
            }
        },
        height: 'auto'
    }
}));

const transitionStyles = {
    hidden: {
        opacity: '0',
        maxHeight: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0',
        overflow: 'hidden'
    },
    shown: {
        opacity: '1',
        maxHeight: maxHeight,
        marginBottom: margin,
        padding: padding,
    }
};

const useItemTransitions = makeStyles({
    enter: {
        transition: `all ${transitionDurationMillis}ms`,
        ...transitionStyles.hidden
    },
    appear: {},
    exit: {
        transition: `all ${transitionDurationMillis}ms`,
        animationIterationCount: '1 !important', // this stops the pulsing animation smoothly
        opacity: pulseAnimationOpacity, // initialise opacity to where the pulsing animation left off
        ...transitionStyles.shown
    },
    enterActive: {
        ...transitionStyles.shown
    },
    appearActive: {},
    exitActive: {
        ...transitionStyles.hidden
    },
    enterDone: {},
    appearDone: {},
    exitDone: {}
});

function describeRecurrance(nextPostAt, repeat) {
    if (!repeat || repeat.mode === 'never') {
        return '';
    }

    const {mode, daysOfWeek, numWeeks, whenInMonth, dayOfWeek:dayOfWeekForMonth} = repeat;
    const time = timeOfDay(nextPostAt);

    if (mode === 'weekly') {
        const daysOfWeekFormatted = daysOfWeek.length === 1 ? shortToLongDayOFWeek(daysOfWeek[0]) : daysOfWeek.join(',');
        return `Every ${daysOfWeekFormatted} at ${time}`;
    }

    if (mode === 'multiweek') {
        const day = dayOfWeek(nextPostAt);
        return `Every ${numWeeks} weeks on ${day} at ${time}`;
    }

    if (mode === 'monthly') {
        const longDayOfWeekForMonth = dayOfWeekForMonth && shortToLongDayOFWeek(dayOfWeekForMonth);

        const when = {
            'firstday': 'First day',
            'lastday': 'Last day',
            'firstweek': 'First ' + longDayOfWeekForMonth,
            'lastweek': 'Last' + longDayOfWeekForMonth
        }[whenInMonth];

        return `${when} of every month at ${time}`;
    }

    return 'Unknown';
}

export default function SurveyItem({survey, onDelete, ...props}) {
    const {id, config, replyCount, prevPostAt, nextPostAt, isDeleting} = survey;
    const {title, repeat, tags = []} = config;

    const classes = useStyles();
    const transitionClasses = useItemTransitions();
    const history = useHistory();

    const [isEditingTags, openEditTagsDialog, closeEditTagsDialog] = useToggle(false);
    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useToggle(false);

    const handleMainClick = () => history.push('/survey/' + survey.id);

    const handleEditClick = e => {
        e.stopPropagation();
        history.push('/edit/' + survey.id);
    };

    const handleDeleteClick = e => {
        e.stopPropagation();
        openDeleteDialog();
    };

    const handleTagsClick = e => {
        e.stopPropagation();
        openEditTagsDialog();
    };

    const recurrenceText = describeRecurrance(nextPostAt, repeat);

    return (<>
        <CSSTransition timeout={transitionDurationMillis} classNames={transitionClasses} {...props}>
            <Paper className={[classes.surveyItem, (isDeleting ? 'deleting' : '')].join(' ')} onClick={handleMainClick}>
                <div className={classes.columnContainer}>
                    <div className={classes.startColumns}>
                        <div className={`${classes.column} ${classes.firstColumn}`}>
                            <Typography variant="h6">{title}</Typography>
                            { prevPostAt && <div className={classes.tagList}>
                                <Chip size="small" variant="outlined" className={classes.responseCountChip} label={'Posted ' + timeSince(prevPostAt)} icon={<DateRangeIcon />} />
                                <Chip size="small" variant="outlined" className={classes.responseCountChip} label={'Responses: ' + replyCount} icon={<FaceIcon />} />
                            </div> }
                        </div>
                        { repeat &&
                            <div className={`${classes.column} ${classes.recurrenceColumn}`}>
                                <Typography variant="overline">{recurrenceText}</Typography>
                                <div className={classes.tagList}>
                                    <Chip size="small" variant="outlined" className={`${classes.responseCountChip} ${classes.nextDueChip}`} label={nextPostAt ? (<><span>Next due on</span> <span>{timeUntil(nextPostAt)}</span></>) : <span>'Never Repeats'</span>} icon={<ScheduleIcon />} />
                                </div>
                            </div>
                        }
                        { !repeat && !prevPostAt && 
                            <div className={`${classes.column} ${classes.recurrenceColumn}`}>
                                <Typography variant="overline">Scheduled For:</Typography>
                                <div className={classes.tagList}>
                                    <Chip size="small" variant="outlined" className={classes.responseCountChip} label={`${timeUntil(nextPostAt)} ${timeOfDay(nextPostAt)}`} icon={<ScheduleIcon />} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className={classes.endColumns}>
                        <div className={`${classes.column} ${classes.tagsColumn}`} onClick={handleTagsClick}>
                            <Typography variant="overline">Tags:</Typography>
                            <div className={classes.tagList}>
                                {tags.map((t, idx) => <Chip key={idx} size="small" label={t} />)}
                                {tags.length === 0 && <Chip color="primary" size="small" label="+ Add" clickable variant="outlined" />}
                            </div>
                        </div>
                        <div className={classes.actionButtonsColumn}>
                            <IconButton
                                color="primary"
                                disabled={isDeleting}
                                onClick={handleEditClick}>
                                <EditIcon />
                            </IconButton>
                            <DeleteIconButton inProgress={isDeleting} onClick={handleDeleteClick} />
                        </div>
                    </div>
                </div>
            </Paper>
        </CSSTransition>
        <EditTagsDialog surveyId={id} tags={tags} open={isEditingTags} onClose={closeEditTagsDialog} />
        <DeleteConfirmationDialog what={title} open={isDeleteDialogOpen} onClose={closeDeleteDialog} onConfirm={() => onDelete(id)} />
    </>);
}

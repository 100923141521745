import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import UserRepliesTable from './UserRepliesTable';

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // just importing is enough to enable this

const DAYS_OF_WEEK = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
];

const DAYS_OF_MONTH = [
    '1st',
    '2nd',
    '3rd',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31'
];

const LAST_TEN = [
    'Newest ⇢',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '⇢ Oldest',
];

const useSummaryCardStyles = makeStyles(theme => ({
    card: {
        whiteSpace: 'nowrap',
        padding: '30px',
        flexBasis: 0,
        flexShrink: 1,
        flexGrow: 1,
        margin: '20px',
        marginTop: '10px',
        boxShadow: '0 1px 20px 0 rgba(0,0,0,.08)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        position: 'absolute',
        right: 10,
        top: 10,
        opacity: 0.4,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[300]
    }
}));

function SummaryCard({value, title, icon}) {
    const classes = useSummaryCardStyles();

    return (
        <Paper className={classes.card}>
            <div>
                <Avatar variant="rounded" className={classes.icon}>{icon}</Avatar>
                <Typography variant="h4" component="h4" align="center">{value}</Typography>
                <Typography variant="button" component="h4" color="textSecondary" align="center">{title}</Typography>
            </div>
        </Paper>
    );
}

const useChartSummaryCardStyles = makeStyles(theme => ({
    card: {
        padding: '8px',
        flexBasis: 0,
        flexShrink: 1,
        flexGrow: 1,
        minWidth: props => `${Math.max(300, props.numXAxisLabels * 33.33)}px`, // numbers experimentally determined to look good
        minHeight: '140px',
        margin: '20px',
        marginTop: '10px',
        boxShadow: '0 1px 20px 0 rgba(0,0,0,.08)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -20,
        marginLeft: -20,
    }
}));

function ChartSummaryCard({title, possibleResponses, data}) {
    const classes = useChartSummaryCardStyles({numXAxisLabels: !possibleResponses ? 0 : possibleResponses.length});

    const isLoading = !possibleResponses || possibleResponses.length === 0;

    return (
        <Paper className={classes.card}>
            {isLoading && <CircularProgress disableShrink className={classes.progress} />}
            {<BarChart labels={possibleResponses} data={data} />}
            <Typography variant="button" component="h4"  color="textSecondary" align="center">{title}</Typography>
        </Paper>
    );
}

function BarChart({labels, data}) {
    const canvasRef = React.useRef();
    const chartRef = React.useRef();

    const color = '#d4d9f3bb';

    React.useEffect(() => {
        // no labels then no chart
        if (!labels || labels.length === 0) { return; }

        const dataValues = labels.map(lbl => data[lbl] || 0);

        // update chart if it already exists
        if (chartRef.current) {
            chartRef.current.data.labels = labels;
            chartRef.current.data.datasets.forEach(ds => ds.data = dataValues);
            chartRef.current.update();
            return;
        }

        // create chart (only happens once)
        chartRef.current = new Chart(canvasRef.current, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    data: dataValues,
                    backgroundColor: color,
                }]
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 12,
                        bottom: 10,
                        right: 10
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        gridLines: {
                            lineWidth: 0,
                            zeroLineColor: color
                        },
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 3,
                            stepSize: 1
                        }
                    }],
                    xAxes: [{
                        display: false
                    }]
                },
                tooltips: {
                    intersect: false,
                    displayColors: false,
                    backgroundColor: '#3f51b5dd',
                    bodyFontStyle: 'bold',
                    bodyFontSize: 14,
                    callbacks: {
                        title: () => '',
                        label: (tooltipItem, data) => tooltipItem.value + ' replies'
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: function(value, context) {
                            return context.chart.data.labels[context.dataIndex];
                        },
                        anchor: 'end',
                        font: {
                            size: 25,
                            lineHeight: 1
                        },
                        borderRadius: 50,
                        backgroundColor: '#fffa',

                    }
                }
            }
        });
    }, [labels, data]);

    return (
        <div style={{position:'relative', flexGrow:1}}>
            <canvas ref={canvasRef} width="1" height="1"></canvas>
        </div>
    );
}

const useResultsDashboardstyles = makeStyles(theme => ({
    headerBar: {
        backgroundColor: '#eaecff',
        '& .MuiTypography-colorTextSecondary': {
            color: '#5e6486'
        },
        paddingTop: '5px'
    },
    avatar: {
        backgroundColor: '#4b5bb5',
        width: '2.3em',
        height: '2.3em',
        boxShadow: '6px 6px 12px #c5cae2, -6px -6px 12px #fbffff'
    },
    overview: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '10px -20px 20px -20px'
    }
}));

function calcDailyResponsesForPeriod(respondants, startDate, numDays, dateToDayIndexFn) {
    const endDate = startDate + numDays * 24 * 60 * 60 * 1000;

    const columnsUtilized = Array(numDays).fill(false); // flags to indicate if column utilised
    let replyCount = 0;
    let respondantCount = 0;

    const usersAndDailyResponses = respondants.map(user => {
        // filter responses to the current displayed period
        const responsesWithinTimeframe = (user.responses || []).filter(r => r.timestamp >= startDate && r.timestamp < endDate);
        
        if (responsesWithinTimeframe.length > 0) {
            replyCount += responsesWithinTimeframe.length;
            respondantCount += 1;
        }

        let latestResponseAt = null;

        // map responses by day of period (day of week or day of month, etc)
        const dailyResponses = Array(numDays).fill('-');
        responsesWithinTimeframe.forEach(r => {
            const dayOfPeriod = dateToDayIndexFn(new Date(r.timestamp));
            dailyResponses[dayOfPeriod] = r.choice;
            columnsUtilized[dayOfPeriod] = true;
            latestResponseAt = Math.max(latestResponseAt, r.timestamp);
        });

        return {user, dailyResponses, latestResponseAt};
    });

    return {usersAndDailyResponses, columnsUtilized, replyCount, respondantCount};
}

function calcLast10Responses(respondants) {
    const MAX = 10;

    const columnsUtilized = Array(MAX).fill(false); // flags to indicate if column utilised
    let replyCount = 0;
    let respondantCount = 0;

    const usersAndDailyResponses = respondants.map(user => {
        // select the most-recent N responses
        const recentResponses = (user.responses || []).sort((a, b) => b.timestamp - a.timestamp).slice(0, MAX);
        
        let latestResponseAt = null;
        if (recentResponses.length > 0) {
            replyCount += recentResponses.length;
            respondantCount += 1;
            latestResponseAt = recentResponses[0].timestamp;
        }

        // map responses to columns
        const dailyResponses = Array(MAX).fill('');
        recentResponses.forEach((r, i) => {
            dailyResponses[i] = r.choice;
            columnsUtilized[i] = true;
        });

        return {user, dailyResponses, latestResponseAt};
    });

    return {usersAndDailyResponses, columnsUtilized, replyCount, respondantCount};
}

function calcResponses(respondants = [], timeUnit, startDate) {
    if (timeUnit === 'recent') {
        return calcLast10Responses(respondants);
    }

    // otherwise week or month
    return calcDailyResponsesForPeriod(
        respondants,
        startDate,
        timeUnit === 'week' ? 7 : 31, // n.b. table is always 31 columns wide even if month is shorter
        timeUnit === 'week' ? (date => date.getDay()) : (date => date.getDate()-1));
}

function calcResponseHistogram(possibleResponses, usersAndDailyResponses) {
    const histogram = {};

    // init with possible responses
    possibleResponses.forEach(r => histogram[r] = 0);

    // accumulate actual responses
    usersAndDailyResponses.forEach(u => {
        u.dailyResponses
            .filter(r => histogram.hasOwnProperty(r)) // ignore values not in the possible responses
            .forEach(r => histogram[r] = histogram[r] + 1);
    });

    return histogram;
}

export default function Overview({survey, users, startDate, timeUnit, maxReplyColumns, isLoadingReplies}) {
    const classes = useResultsDashboardstyles();

    const {usersAndDailyResponses, columnsUtilized, replyCount, respondantCount} = calcResponses(users, timeUnit, startDate);
    const usersPolled = users && users.length || 0;
    const possibleResponses = survey ? survey.config.emojis : [];
    const histogram = calcResponseHistogram(possibleResponses, usersAndDailyResponses);

    // calc engagement
    const dayCount = columnsUtilized.filter(isUsed => isUsed).length;
    const maxPossibleReplies = respondantCount * dayCount;
    const engagementPercent = maxPossibleReplies === 0 ? 0 : Math.round(replyCount / maxPossibleReplies * 100);

    return (
        <Container component="main">
            <div className={classes.overview}>
                <SummaryCard title="Users" value={usersPolled} icon={<PeopleAltIcon />}/>
                <SummaryCard title="Respondants" value={respondantCount} icon={<PeopleAltIcon />}/>
                <SummaryCard title="Replies" value={replyCount} icon={<QuestionAnswerIcon/>}/>
                <SummaryCard title="Engagement" value={timeUnit === 'recent' ? '-' : engagementPercent + '%'} icon={<DataUsageIcon/>}/>
                <ChartSummaryCard title="Totals" possibleResponses={possibleResponses} data={histogram} />
            </div>

            <UserRepliesTable
                headings={timeUnit === 'week' ? DAYS_OF_WEEK : timeUnit === 'month' ? DAYS_OF_MONTH : LAST_TEN}
                usersAndResponses={usersAndDailyResponses}
                maxReplyColumns={maxReplyColumns}
                mostRecentPostAt={survey ? survey.prevPostAt : null}
                mentionTimePeriod={timeUnit !== 'recent'}
                isLoading={isLoadingReplies}
            />
        </Container>
    );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useAsyncCallback from '../../../hooks/useAsyncCallback';

const INITIAL_STATE = {
    email1: '',
    email2: '',
    showAsError: false
};

export default function ChangeEmailDialog({open, onClose, onSubmit}) {
    const [email1, setEmail1] = React.useState(INITIAL_STATE.email1);
    const [email2, setEmail2] = React.useState(INITIAL_STATE.email2);
    const [showAsError, setShowAsError] = React.useState(INITIAL_STATE.showAsError);

    const mismatch = email1 !== email2;

    const disableSubmit = email1 === '' || email2 === '';
    const formIsValid = !mismatch && !disableSubmit;

    const handleEmail1Change = (event) => setEmail1(event.target.value);
    const handleEmail2Change = (event) => setEmail2(event.target.value);

    const [doOnSubmit, {isPending}] = useAsyncCallback(onSubmit);

    React.useEffect(() => {
        if (!open) { // reset state on close
            setEmail1(INITIAL_STATE.email1);
            setEmail2(INITIAL_STATE.email2);
            setShowAsError(INITIAL_STATE.showAsError);
        }
    }, [open]);
    
    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (!formIsValid) {
            setShowAsError(true);
            return;
        }

        doOnSubmit(email1);
    };

    return (
        <Dialog open={open}>
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>Change Your Email Address</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We will send a verification email your new email address.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="New Email Address"
                        type="email"
                        disabled={isPending}
                        onChange={handleEmail1Change}
                    />
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="New Email Address (Again)"
                        type="email"
                        disabled={isPending}
                        error={showAsError && mismatch}
                        helperText={showAsError && mismatch ? 'Both emails must match' : null}
                        onChange={handleEmail2Change}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={isPending} color="primary">Cancel</Button>
                    <Button type="submit" disabled={disableSubmit || isPending} color="primary">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
import React from 'react';
import { useParams } from "react-router-dom";

import SurveyForm from './SurveyForm';

import useActions from '../../hooks/useActions';
import useAsyncCallback from '../../hooks/useAsyncCallback';
import useSurvey from '../../hooks/useSurvey';

export default function EditForm() {
    const {surveyID} = useParams();
    const [survey, isLoading, loadingErrorMsg] = useSurvey(surveyID);
    const actions = useActions();
    const [editSurvey, {isPending, errorMsg}] = useAsyncCallback(actions.editSurvey);

    const breadcrumbs = [
        { to: '/', text: 'Surveys' },
        { to: `/survey/${surveyID}`, text: survey ? survey.config.title : '' },
        { text: 'Edit' }
    ];

    return (
        isLoading ? <SurveyForm isPending navCrumbs={breadcrumbs} />
        : loadingErrorMsg ? <SurveyForm isPending errorMsg={loadingErrorMsg} navCrumbs={breadcrumbs} />
        : survey ? <SurveyForm
                        key={survey.id} // unique key ensures component re-renders which is important to refresh initialState
                        initialState={{
                            ...survey.config,
                            postAt: survey.nextPostAt,
                            postNow: false
                        }}
                        isPending={isPending}
                        onSubmit={updates => editSurvey(survey.id, updates)}
                        errorMsg={errorMsg}
                        navCrumbs={breadcrumbs}/>
        : <SurveyForm isPending errorMsg="Survey not found" navCrumbs={breadcrumbs} />
    );
 }

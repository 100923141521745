import React from 'react';
import Typography from '@material-ui/core/Typography';
import PaymentIcon from '@material-ui/icons/Payment';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import Header from '../Header';

import useSubscriptionDetails from '../../../hooks/useSubscriptionDetails';

import {formatAsMoney} from '../../../common/formatcurrency';
import {timeUntil} from '../../../common/formattime';


function InlinePaddle({paymentUpdateURL}) {
    React.useEffect(() => {
        if (paymentUpdateURL) {
            window.Paddle.Setup({ vendor: window.PADDLE_CONFIG.vendorID });
            window.Paddle.Checkout.open({
                override: paymentUpdateURL,
                method: 'inline',
                frameTarget: 'paddle-inline-container',
                frameInitialHeight: 366,
                frameStyle: 'background-color: transparent; border: none; width: 100%;'
            });
        }
    }, [paymentUpdateURL]);

    return (<>
        <Typography variant="body2">Change or update your payment details.</Typography>
        <div className="paddle-inline-container"></div>
    </>);
}

export default function Payments() {

    const [{paymentUpdateURL, lastPayment, nextPayment}, {isOnTrial, trialExpiresAt}, isPlanLoadPending, planLoadError] = useSubscriptionDetails();
    
    return (<>
        <Header icon={PaymentIcon}>Payment Method</Header>

        {
            isPlanLoadPending ? <Typography>Loading...</Typography> :
            planLoadError ? <Typography>Error loading plan details, try refreshing your browser: {planLoadError}</Typography> :
            paymentUpdateURL ? <InlinePaddle paymentUpdateURL={paymentUpdateURL}/> :
            <Typography>You are not currently subscribed to any plan. Purchase a plan today and you'll be able to manage your payment details here.</Typography>
        }

        {isOnTrial && <Alert severity="info">
            <Typography variant="body2"><b>Your free trial ends: </b>{timeUntil(trialExpiresAt)}</Typography>
        </Alert>}

        {lastPayment.date && nextPayment.date &&
            <Alert severity="info">
                <AlertTitle>Your Plan Charges</AlertTitle>
                <Typography variant="body2"><b>Last Payment:</b> {formatAsMoney(lastPayment.amount, 'US', lastPayment.currency)} {lastPayment.date}</Typography>
                <Typography variant="body2"><b>Next Payment:</b> {formatAsMoney(nextPayment.amount, 'US', nextPayment.currency)} {nextPayment.date}</Typography>
            </Alert>
        }
    </>);
}
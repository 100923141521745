import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

const NICKNAME_ATTR_KEY = 'nickname';
const EMAIL_ATTR_KEY = 'email';
const EMAIL_VERIFIED_ATTR_KEY = 'email_verified';
const TRIAL_EXPIRATION_ATTR_KEY = 'custom:trial_expiration';
const PADDLE_SUBSCRIPTION_ID_ATTR_KEY = 'custom:paddle_sub_id';

const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: window.CLOUD_CONFIG.CognitoUserPool,
    ClientId: window.CLOUD_CONFIG.CognitoUserPoolClient
});

let currentUser = userPool.getCurrentUser();


function sessionPromise(callback) {
    return new Promise((resolve, reject) => {
        if (!currentUser) {
            const err = new Error('No user logged in');
            err.sessionExpired = true; // allow for detection of this error by Redux actions/reducers
            reject(err);
        }
        
        currentUser.getSession((error) => {
            if (error) {
                currentUser.signOut();
                error.sessionExpired = true; // allow for detection of this error by Redux actions/reducers
                reject(error);
            } else {
                callback(resolve, reject);
            }
        });
    });
}

export function getUserID() {
    if (!currentUser) {
        return Promise.resolve(-1);
    }

    return sessionPromise((resolve) => {
        resolve(currentUser.getUsername());
    });
}

export function getCurrentJwtIdToken() {
    if (!currentUser) {
        return Promise.resolve(null);
    }

    return sessionPromise((resolve) => {
        resolve(currentUser.getSignInUserSession().getIdToken().getJwtToken());
    });
}

export function refreshSession() {
    return sessionPromise((resolve, reject) => {
        const refreshToken = currentUser.getSignInUserSession().getRefreshToken();
        currentUser.refreshSession(refreshToken, (err) => {
            err ? reject(err) : resolve();
        });
    });
}

export function getUserAttributes() {
    if (!currentUser) {
        return Promise.resolve({nickname: 'Dev User', email: 'nobody@nowhere.com', isEmailVerified:true});
    }

    return sessionPromise((resolve, reject) => {
        currentUser.getUserAttributes((error, attrs) => {
            if (error) { reject(error); return; }

            const attrObj = {};
            for (const attr of attrs) {
                attrObj[attr.Name] = attr.Value;
            }
            
            resolve({
                nickname: attrObj[NICKNAME_ATTR_KEY] || 'Unknown Name',
                email: attrObj[EMAIL_ATTR_KEY] || 'Unknown Email',
                isEmailVerified: attrObj[EMAIL_VERIFIED_ATTR_KEY] === 'true',
                trialExpiration: attrObj[TRIAL_EXPIRATION_ATTR_KEY] || null,
                paddleSubscriptionID: attrObj[PADDLE_SUBSCRIPTION_ID_ATTR_KEY] || null
            });
        });
    });
}

export function registerUser (nickname, email, password, acceptsMarketing) {
    return new Promise((resolve, reject) => {
        const nicknameAttr = new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: NICKNAME_ATTR_KEY,
            Value: nickname
        });

        const clientMetadata = {
            acceptsMarketing: acceptsMarketing.toString()
        };

        userPool.signUp(email, password, [nicknameAttr], null, ((error, result) =>
            error ? reject(error) : resolve(result)),
            clientMetadata
        );
    });
}

export function confirmUserRegistration (userid, confirmationCode) {
    const confirmingUser = new AmazonCognitoIdentity.CognitoUser({
        Username: userid,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        confirmingUser.confirmRegistration(confirmationCode, true, (error, result) =>
            error ? reject(error) : resolve(result)
        );
    });
}

export function resendSignupVerification(email) {
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool
    });

    return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((error, result) =>
            error ? reject(error) : resolve(result)
        );
    });
}

export function loginUser (email, password) {
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: email,
        Password: password
    });

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool
    });

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: session => {
                // record the currently authenticated user for future calls
                currentUser = cognitoUser;
                resolve();
            },
            onFailure: reject
        });
    });
}

export function logoutUser() {
    if (currentUser) {
        currentUser.signOut();
        currentUser = null;
    }
}

export function forgotPassword(email) {
    const nonAuthenticatedUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        nonAuthenticatedUser.forgotPassword({
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

export function resetPassword(userid, verificationCode, newPassword) {
    const nonAuthenticatedUser = new AmazonCognitoIdentity.CognitoUser({
        Username: userid,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        nonAuthenticatedUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

export function changePassword(oldPassword, newPassword) {
    if (!currentUser) {
        return Promise.reject(new Error('No User'));
    }

    return new Promise((resolve, reject) => {
        currentUser.changePassword(oldPassword, newPassword, (error, result) =>
            error ? reject(error) : resolve(result)
        );
    });
}

export function changeNickName(newNickname) {
    if (!currentUser) {
        return Promise.resolve('No user');
    }

    return sessionPromise((resolve, reject) => {
        const nicknameAttr = new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: NICKNAME_ATTR_KEY,
            Value: newNickname
        });

        currentUser.updateAttributes([nicknameAttr], (error, result) =>
            error ? reject(error) : resolve(result)
        );
    });
}

export function confirmEmailChange(confirmationCode) {
    if (!currentUser) {
        return Promise.resolve('No user');
    }

    return sessionPromise((resolve, reject) => {
        currentUser.verifyAttribute(EMAIL_ATTR_KEY, confirmationCode, {
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err)
        });
    });
}

export function resendEmailChangeVerification() {
    if (!currentUser) { 
        return Promise.resolve('No user');
    }

    return sessionPromise((resolve, reject) => {
        currentUser.getAttributeVerificationCode(EMAIL_ATTR_KEY, {
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err),
            inputVerificationCode: null
        });
    });
}

import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import DayOfWeekPicker from '../../components/DayOfWeekPicker';

import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';



export default function RepeatOptions({options, onChangeOptions, enableCustomTimeOfDay, disabled}) {
    const {
        mode = 'never',
        numWeeks = 2,
        daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri'],
        whenInMonth = 'firstday',
        dayInMonth = null,
        timeOfDay = Date.now()
    } = (options || {});

    const optionsChanged = (updatedOptions) => {
        const nextOptions = {
            mode, numWeeks, daysOfWeek, whenInMonth, dayInMonth, timeOfDay,
            ...updatedOptions
        };
        if (onChangeOptions && JSON.stringify(nextOptions) !== JSON.stringify(options)) {
            onChangeOptions(nextOptions);
        }
    };

    const handlerecurrenceChange = (event) => {
        optionsChanged({mode: event.target.value});
    }

    const handleNumWeeksChange = (event) => {
        optionsChanged({numWeeks: Math.max(1, event.target.value)});
    }

    const handleDaysOfWeekChange = (days) => {
        optionsChanged({daysOfWeek: days});
    }

    const handleWhenInMonthChange = (event) => {
        optionsChanged({whenInMonth: event.target.value});
    }

    const handleDaysOfMonthChange = (day) => {
        optionsChanged({dayInMonth: day});
    }

    const handleTimeOfDayChange = (datetime) => {
        optionsChanged({timeOfDay: datetime.unix() * 1000});
    }

    return (<>
        <FormControl disabled={disabled} fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Recurrence</InputLabel>
            <Select label="Recurrence *"  value={mode} onChange={handlerecurrenceChange}>
                <MenuItem value={'never'}>Never Repeats</MenuItem>
                <MenuItem value={'weekly'}>Repeats Weekly (On Certain Days)</MenuItem>
                <MenuItem value={'multiweek'}>Repeats Every Few Weeks</MenuItem>
                <MenuItem value={'monthly'}>Repeats Monthly</MenuItem>
            </Select>   
        </FormControl>

        { mode === 'weekly' &&
            <FormControl disabled={disabled} fullWidth margin="normal" required>
                <FormLabel className="MuiInputLabel-shrink">Select Days</FormLabel>
                <DayOfWeekPicker value={daysOfWeek} onChange={handleDaysOfWeekChange} allowMultiple disabled={disabled} />
            </FormControl>
        }

        { mode === 'multiweek' &&
            <TextField
                required
                fullWidth
                margin="normal"
                label="How many weeks?"
                type="number"
                value={numWeeks}
                onChange={handleNumWeeksChange}
                disabled={disabled}
                InputProps={{
                    endAdornment: <InputAdornment position="end">Weeks</InputAdornment>,
                }}
            />
        }

        { mode === 'monthly' &&
            <>
                <FormControl disabled={disabled} fullWidth margin="normal" required>
                    <InputLabel>When?</InputLabel>
                    <Select label="When?" value={whenInMonth} onChange={handleWhenInMonthChange}>
                        <MenuItem value={'firstday'}>First Day of the Month</MenuItem>
                        <MenuItem value={'lastday'}>Last Day of the Month</MenuItem>
                        <MenuItem value={'firstweek'}>First Occurence of [select a day]...</MenuItem>
                        <MenuItem value={'lastweek'}>Last Occurence of [select a day]...</MenuItem>
                    </Select>   
                </FormControl>

                { (whenInMonth === 'firstweek' || whenInMonth === 'lastweek') &&
                    <FormControl disabled={disabled} fullWidth margin="normal" required>
                        <FormLabel className="MuiInputLabel-shrink">Select a Day</FormLabel>
                        <DayOfWeekPicker value={dayInMonth} onChange={handleDaysOfMonthChange} allowMultiple={false} disabled={disabled} />
                    </FormControl>
                }
            </>
        }

        { mode !== 'never' &&
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <TimePicker
                    disabled={disabled || !enableCustomTimeOfDay}
                    margin="normal"
                    fullWidth
                    required={enableCustomTimeOfDay}
                    ampm={false}
                    variant="dialog"
                    label={enableCustomTimeOfDay ? 'Time of Day:' : 'Time of Day will be the same as the initial post:'}
                    value={new Date(timeOfDay)}
                    onChange={handleTimeOfDayChange}
                    minutesStep={5}
                />
            </MuiPickersUtilsProvider>
        }
    </>);
}

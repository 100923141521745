import React from 'react';
import { useDispatch } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import Logo from '../../components/Logo';
import MainAppBar from '../../components/MainAppBar';
import { TransitionRouteSet } from '../../components/TransitionRoute';

import useActions from '../../hooks/useActions';
import useUserInfo from '../../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
    snackbarContainer: {
        marginTop: theme.spacing(8)
    }
}));

export default function AppContainer({pageRoutes, defaultRouteChild}) {
    const classes = useStyles();

    const {userSignOut, loadExistingSessionData, loadSubscriptionPlan} = useActions();
    const user = useUserInfo();

    // do this in the AppContainer because most other routes won't even have session
    // data and those that do first route you via the login screen which calls this anyway.
    React.useEffect(() => {
        loadExistingSessionData();
    }, []);

    return (<>
        <MainAppBar
            appName={<Logo/>}
            username={user.name}
            navButton={{
                path: '/surveys',
                text: 'Pulse Surveys',
                icon: <QuestionAnswerIcon/>
            }}
            menuItems={[
                {
                    icon: <SettingsIcon/>,
                    text: 'Account Settings',
                    linkTo: "/account"
                },
                {
                    icon: <ExitToAppIcon/>,
                    text: 'Sign Out',
                    onClick: userSignOut
                }
            ]}
        />

        <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{containerAnchorOriginTopRight: classes.snackbarContainer}}>
            <TransitionRouteSet routes={pageRoutes} defaultRouteChild={defaultRouteChild} />
        </SnackbarProvider>
    </>);
}
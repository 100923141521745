import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    banner: {
        color: theme.palette.success.dark,
        borderColor: theme.palette.success.light,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0.5)
    },
    icon: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    }
}));


export default function SuccessBanner({text, className, ...other}) {
    const classes = useStyles();

    if (!text) { return null; }

    return (
        <Paper
            className={`${classes.banner} ${className}`}
            variant="outlined"
            {...other}
        >
            <CheckCircleOutlineIcon className={classes.icon} />
            <Typography variant="button">{text}</Typography>
        </Paper>
    );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import NoResults from './NoResults';

const useReplyRowStyles = makeStyles(theme => ({
    username: {
        fontWeight: 'bold',
        fontSize: '1.1em',
        wordWrap: 'break-word'
    },
    emoji: {
        fontSize: props => props.denseFont ? '2em' : '3em'
    }
}));

function UserReplyRow({user, responses, maxReplyColumns}) {
    const classes = useReplyRowStyles({denseFont: responses.length > 10});

    return (
        <TableRow>
            <TableCell><Avatar variant="rounded" alt={user.name} src={user.avatar} /></TableCell>
            <TableCell className={classes.username}>{user.name}</TableCell>
            {responses.map((choice, i) => <TableCell key={i} align="center" padding="none" className={classes.emoji}>{i >= maxReplyColumns ? '' : choice}</TableCell>)}
        </TableRow>
    );
}

const useRepliesTableStyles = makeStyles(theme => ({
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    replies: {
        boxShadow: '0 1px 20px 0 rgba(0,0,0,.08)',
        '& table tr:last-child td': {
            border: 'initial'
        },
        padding: '20px',
        position: 'relative'
    },
    overflowWrap: {
        overflow: 'auto' // for horizontal scroll when window is too narrow
    },
    table: {
        tableLayout: 'fixed',
        width: 'auto', // override MUI to allow width to be >100%
        minWidth: '100%',
        '& th': {
            minWidth: '2em',
            padding: '0.1em'
        },
        '& th:nth-child(1)': {
            width: '64px'
        },
        '& th:nth-child(2)': {
            width: '10em'
        },
        opacity: props => props.isLoading ? 0.3 : 1
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -20,
        marginLeft: -20,
        zIndex: 100
    }
}));

function filterRows(usersAndResponses, filterBy, mostRecentPostAt) {
    if (filterBy === 'respondants') {
        return usersAndResponses.filter(u => u.latestResponseAt !== null);
    }

    if (filterBy === 'still-pending') {
        return usersAndResponses.filter(u => u.latestResponseAt < mostRecentPostAt);
    }

    return usersAndResponses;
}

export default function UserRepliesTable({headings, usersAndResponses, maxReplyColumns, mostRecentPostAt, mentionTimePeriod, isLoading}) {
    const classes = useRepliesTableStyles({isLoading});

    const [filterBy, setFilterBy] = React.useState('all');

    const handleChangeFilter = event => setFilterBy(event.target.value);

    const rows = filterRows(usersAndResponses, filterBy, mostRecentPostAt);

    return (<>
        <Paper elevation={5} className={classes.replies}>
            <div className={classes.controls}>
                <Typography variant="button" color="textSecondary">{mentionTimePeriod ? 'Daily' : 'Recent'} Results</Typography>
                <Select disableUnderline autoWidth value={filterBy} onChange={handleChangeFilter} disabled={isLoading}>
                    <MenuItem value="all">All Users</MenuItem>
                    <MenuItem value="respondants">Respondants{mentionTimePeriod ? ' (In This Period)' : ''}</MenuItem>
                    <MenuItem value="still-pending">Users Pending Latest Response</MenuItem>
                </Select>
            </div>
            <div className={classes.overflowWrap}>
                <Table stickyHeader size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            {headings.map((h, i) => <TableCell key={i} align="center" padding="none">{i >= maxReplyColumns ? '' : h}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(u => <UserReplyRow key={u.user.slackUserId} user={u.user} responses={u.dailyResponses} maxReplyColumns={maxReplyColumns} />)}
                    </TableBody>
                </Table>
                {rows.length === 0 && !isLoading && <NoResults/>}
            </div>
            {isLoading && <CircularProgress disableShrink className={classes.progress} />}
        </Paper>
    </>);
}
    
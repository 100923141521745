import { useState, useCallback } from 'react';

export default function useToggle(initial) {
    const [flag, setFlag] = useState(!!initial);

    const enable = useCallback(() => {
        setFlag(true);
    }, [setFlag]);

    const disable = useCallback(() => {
        setFlag(false);
    }, [setFlag]);

    return [flag, enable, disable, setFlag]
}
import React from 'react';
import { Link } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import ErrorBanner from '../../components/ErrorBanner';

import useIntegrations from '../../hooks/useIntegrations';

function mapToChannelsList(slackIntegrations, enabled) {
    return slackIntegrations.flatMap(({id:workspaceOrEnterpriseId, channels}) => {
        return channels.map(c => (
            {workspaceOrEnterpriseId, disabled:!enabled, ...c}
        ));
    });
}

export default function ChannelSelect({value, disabled, onChange, ...props}) {
    const [enabledSlackIntegrations, disabledSlackIntegrations, isIntegrationsPending, integrationsErrorMsg] = useIntegrations();

    const channels = React.useMemo(() => (
        [   ...mapToChannelsList(enabledSlackIntegrations, true),
            ...mapToChannelsList(disabledSlackIntegrations, false)
        ]
    ), [enabledSlackIntegrations, disabledSlackIntegrations]);

    const currentIdx = React.useMemo(() => 
        !value ? -1 : channels.findIndex(c => value.workspaceOrEnterpriseId === c.workspaceOrEnterpriseId && value.channel === c.id)
    , [channels, value && value.workspaceOrEnterpriseId, value && value.channel]);

    const handleChange = (event) => {
        if (onChange && event.target.value !== undefined) {
            onChange(channels[event.target.value]);
        }
    };

    var currentWorkspaceGroup = null;

    return (<>
        <FormControl {...props} disabled={disabled || isIntegrationsPending}>
            <InputLabel>{props.label}</InputLabel>
            <Select label={props.label + ' *'} value={currentIdx === -1 ? '' : currentIdx} onChange={handleChange}>
                {channels.map((c, idx) => {
                    const newGroup = c.workspaceName !== currentWorkspaceGroup;
                    if (newGroup) {
                        currentWorkspaceGroup = c.workspaceName;
                    }

                    return [
                        newGroup && <ListSubheader>Workspace: {currentWorkspaceGroup}{c.disabled && ' (Disabled)'}</ListSubheader>,
                        <MenuItem value={idx} disabled={c.disabled}># {c.name}</MenuItem>
                    ]
                })}
            </Select>
        </FormControl>
        {disabledSlackIntegrations.length > 0 && <>
            <Typography variant="body2">⚠️ Some channels are disabled because you have more Slack integrations than your Team Feels plan supports. <Link to="/account/subscription">Upgrade your plan today to re-enable multiple teams!</Link></Typography>
        </>}
        <ErrorBanner text={integrationsErrorMsg} />
    </>);
}

import React from 'react';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    errorAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.error.dark,
    },
    header: {
        marginBottom: theme.spacing(2),
        color: theme.palette.error.dark
    }
}));

export default function InvalidURLParamsScreen() {
    const classes = useStyles();

    return (
        <Container className={classes.container} maxWidth="sm">
            <Avatar className={classes.errorAvatar}>
                <PriorityHighIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className={classes.header}>Invalid URL Parameters</Typography>
            <Typography>Please double-check that the URL you are visiting is correct.</Typography>
            <Typography>If you need help just contact us.</Typography>
        </Container>
    );
}

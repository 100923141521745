import axios from 'axios';

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) { // not all errors have response (preflight CORS errors for example)
            // allow for detection of this error by Redux actions/reducers
            if (error.response.status === 401) {
                error.sessionExpired = true;
            }

            // if the error came from our backend then our lambdas pass an error
            // object back so concat its message in as well
            if (error.response.data && error.response.data.message) {
                error.message += ': ' + error.response.data.message;
            }
        }

        return Promise.reject(error);
    }
);

export async function getAccountDetails(authToken) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/account`, {
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function changeEmail(authToken, newEmail) {
    const resp = await axios.put(`${window.CLOUD_CONFIG.APIBaseURL}/account/email`, JSON.stringify(newEmail), {
        headers: {
            'X-Authentication-Token': authToken,
            'Content-Type': 'application/json'
        }
    });
    return resp.data;
}

export async function getMailingListSubscriptionStatus(authToken) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/mailinglist/subscription`, {
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function changeMailingListSubscriptionStatus(authToken, newStatus) {
    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/mailinglist/subscription/update`, null, {
        params: {
            newStatus
        },
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}


export async function loadSubscriptionPlan(authToken) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/paddle/subscription`, {
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function changeSubscriptionPlan(authToken, newPaddlePlanID) {
    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/paddle/subscription/update`, null, {
        params: {
            newPlanID: newPaddlePlanID
        },
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function cancelSubscriptionPlan(authToken) {
    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/paddle/subscription/cancel`, null, {
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function slackOAuthTokenExchange(authToken, code) {
    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/slack/oauth`, null, {
        params: {
            code
        },
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function slackListIntegrations(authToken) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/slack/integrations`, {
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function slackDeleteIntegration(authToken, workspaceOrEnterpriseId) {
    const resp = await axios.delete(`${window.CLOUD_CONFIG.APIBaseURL}/slack/integrations`, {
        params: {
            workspaceOrEnterpriseId
        },
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function listSurveys(authToken) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/list`, {
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function addSurvey(authToken, surveyDetails) {
    const payload = { // re-pack the payload, mainly for documentative purposes
        title: surveyDetails.title,
        question: surveyDetails.question,
        workspaceOrEnterpriseId: surveyDetails.workspaceOrEnterpriseId,
        channel: surveyDetails.channel,
        emojis: surveyDetails.emojis,
        isAnon: surveyDetails.isAnon,
        repeat: {
            mode: surveyDetails.repeat.mode,
            timeOfDay: surveyDetails.repeat.timeOfDay,
            daysOfWeek: surveyDetails.repeat.daysOfWeek,
            numWeeks: surveyDetails.repeat.numWeeks,
            whenInMonth: surveyDetails.repeat.whenInMonth,
            dayOfWeek: surveyDetails.repeat.dayOfWeek
        },
        postAt: surveyDetails.postAt,
        tags: surveyDetails.tags
    };

    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/create`,
        payload,
        {
            headers: {
                'X-Authentication-Token': authToken
            }
        }
    );
    return resp.data;
}

export async function editSurvey(authToken, surveyId, surveyDetails) {
    const payload = { // re-pack the payload, mainly for documentative purposes
        id: surveyId,
        title: surveyDetails.title,
        question: surveyDetails.question,
        workspaceOrEnterpriseId: surveyDetails.workspaceOrEnterpriseId,
        channel: surveyDetails.channel,
        emojis: surveyDetails.emojis,
        isAnon: surveyDetails.isAnon,
        repeat: {
            mode: surveyDetails.repeat.mode,
            timeOfDay: surveyDetails.repeat.timeOfDay,
            daysOfWeek: surveyDetails.repeat.daysOfWeek,
            numWeeks: surveyDetails.repeat.numWeeks,
            whenInMonth: surveyDetails.repeat.whenInMonth,
            dayOfWeek: surveyDetails.repeat.dayOfWeek
        },
        postAt: surveyDetails.postAt,
        tags: surveyDetails.tags
    };

    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/edit`,
        payload,
        {
            headers: {
                'X-Authentication-Token': authToken
            }
        }
    );
    return resp.data;
}

export async function updateSurveyTags(authToken, surveyId, tags) {
    const payload = {
        id: surveyId,
        tags
    };
    const resp = await axios.put(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/edit/tags`,
        payload,
        {
            headers: {
                'X-Authentication-Token': authToken
            }
        }
    );
    return resp.data;
}

export async function deleteSurvey(authToken, id) {
    const resp = await axios.delete(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/delete`, {
        params: {
            id
        },
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function listReplies(authToken, id, workspaceOrEnterpriseId, isAnon) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/replies`, {
        params: {
            id,
            workspaceOrEnterpriseId,
            anon: isAnon
        },
        headers: {
            'X-Authentication-Token': authToken
        }
    });
    return resp.data;
}

export async function getSharedSurvey(id) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/shared`, {
        params: {
            id,
            isShared: true
        }
    });
    return resp.data;
}


export async function listSharedReplies(id, workspaceOrEnterpriseId, isAnon) {
    const resp = await axios.get(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/shared/replies`, {
        params: {
            id,
            workspaceOrEnterpriseId,
            anon: isAnon,
            isShared: true
        }
    });
    return resp.data;
}

export async function shareSurvey(authToken, id) {
    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/share`, null,
        {
            params: {
                id
            },
            headers: {
                'X-Authentication-Token': authToken
            }
        }
    );
    return resp.data;
}

export async function unshareSurvey(authToken, id) {
    const resp = await axios.post(`${window.CLOUD_CONFIG.APIBaseURL}/surveys/unshare`, null,
        {
            params: {
                id
            },
            headers: {
                'X-Authentication-Token': authToken
            }
        }
    );
    return resp.data;
}

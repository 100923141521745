import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CheckIcon from '@material-ui/icons/Check';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import InvalidURLParamsScreen from '../../components/InvalidURLParamsScreen';
import SpinnerButton from '../../components/SpinnerButton';
import ErrorBanner from '../../components/ErrorBanner';

import useAsyncCallback from '../../hooks/useAsyncCallback';

import * as auth from '../../api/auth';
import parseQueryParams from '../../common/parsequeryparams';


const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    successAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.success.main,
    },
    errorAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
    },
    pendingAvatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.light
    },
    header: {
        marginBottom: theme.spacing(2)
    },
    spinner: {
        marginTop: theme.spacing(1)
    },
    errorText: {
        fontWeight: 'bold',
        color: theme.palette.error.main,
        fontSize: '1.5em',
        marginBottom: theme.spacing(5)
    },
    button: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(1),
        width: '50%'
    }
}));

function LoadingContent() {
    const classes = useStyles();

    return (<>
        <Avatar className={classes.pendingAvatar}>
            <HourglassEmptyIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.header}>Confirming</Typography>
        <CircularProgress disableShrink className={classes.spinner}/>
        <Typography>Please Wait...</Typography>
    </>);
}

function SuccessContent({name, msg}) {
    const classes = useStyles();

    return (<>
        <Avatar className={classes.successAvatar}>
            <CheckIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.header}>Registration Confirmed</Typography>
        <Typography>Thanks {name}</Typography>
        <Typography variant='overline'>{msg}</Typography>
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to='/signin'>Get Started <ArrowRightIcon/></Button>
    </>);
}

function FailureContent({name, user, msg}) {
    const classes = useStyles();

    const [resendEmail, {isPending, errorMsg:resendErrorMsg}] = useAsyncCallback(auth.resendSignupVerification);

    return (<>
        <Avatar className={classes.errorAvatar}>
            <PriorityHighIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.header}>Confirmation Failed</Typography>
        <Typography>{name || 'Whoops'} we were unable to confirm your account:</Typography>
        <Typography className={classes.errorText}>{msg}</Typography>
        <Typography>Refresh this page to try again, or try again later.</Typography>
        <Typography>If you need help just contact us.</Typography>
        <SpinnerButton
            variant="contained"
            color="secondary"
            pending={isPending}
            className={classes.button}
            onClick={() => resendEmail(user)}>
            Resend Confirmation Email
        </SpinnerButton>
        <ErrorBanner text={resendErrorMsg} />
    </>);
}

export default function ConfirmScreen() {
    const classes = useStyles();

    const [confirmUserRegistration, {isSuccess, errorMsg:rawErrorMsg}] = useAsyncCallback(auth.confirmUserRegistration);

    // extract query params from URL
    // these must match the URL constructed by the Cognito custom-message lambda
    const {name, user, code} = parseQueryParams();

    // on-mount effect to confirm the user
    React.useEffect(() => {
        if (user && code) {
            confirmUserRegistration(user, code);
        }
    }, [user, code]);

    if (!user || !code || !name) {
        return <InvalidURLParamsScreen/>;
    }

    // interpret the response from the async function
    let successMsg = null;
    let errorMsg = null;
    if (isSuccess) {
        successMsg = 'Your account is now confirmed.';
    }
    else if (rawErrorMsg && rawErrorMsg.includes('Current status is CONFIRMED')) {
        successMsg = 'Your account is (already) confirmed.';
    }
    else {
        errorMsg = rawErrorMsg;
    }

    return (
        <Container className={classes.container} maxWidth="sm">
            {successMsg ? <SuccessContent name={name} msg={successMsg}/>
            : errorMsg ? <FailureContent name={name} user={user} msg={errorMsg}/>
            : <LoadingContent/>}
        </Container>
    );
}

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useSurveyList() {
    const [loadSurveys, {isPending, errorMsg}] = useAsyncCallback(actions.loadSurveys());
    const surveys = useSelector(state => state.surveys.all);

    // load on first mount
    const dispatch = useDispatch();
    useEffect(() => {
        if (surveys.length === 0) {
            dispatch(loadSurveys);
        }
    }, []);

    return [
        surveys,
        isPending,
        errorMsg
    ];
}
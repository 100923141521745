import React from 'react';
import ReactDOM from 'react-dom';
import * as Redux from 'redux';
import { Provider } from 'react-redux';
import ReduxThunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import App from './views/App';
import * as serviceWorker from './serviceWorker';

window.Paddle.Setup({
    vendor: window.PADDLE_CONFIG.vendorID,
});

const initialState = undefined;
const store = Redux.createStore(
    rootReducer,
    initialState,
    Redux.applyMiddleware(ReduxThunkMiddleware));

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

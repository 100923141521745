import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import SimpleMenuButton from '../../components/SimpleMenuButton';


export default function MoreOptionsMenu({surveyID, onExportToCSV, onDelete, shareBtnRef}) {
    const menuItems = [
        {
            icon: <EditIcon/>,
            text: 'Edit',
            linkTo: '/edit/' + surveyID
        },
        {
            icon: <ShareIcon/>,
            text: 'Share',
            onClick: () => shareBtnRef.current.click()
        },

        ...(onExportToCSV ? [{
            icon: <DownloadIcon/>,
            text: 'Export to CSV',
            onClick: () => onExportToCSV(surveyID)
        }] : []),

        {
            icon: <DeleteIcon/>,
            text: 'Delete',
            onClick: () => onDelete(surveyID)
        }
    ];

    return (
        <SimpleMenuButton
            btnElement={IconButton}
            items={menuItems}>
                <MoreVertIcon/>
        </SimpleMenuButton>
    );
}

import React from 'react';
import { Redirect } from 'react-router-dom';

import SurveyForm from './SurveyForm';

import useActions from '../../hooks/useActions';
import useAsyncCallback from '../../hooks/useAsyncCallback';
import useSurveyList from '../../hooks/useSurveyList';
import useCapFlags from '../../hooks/useCapFlags';

export default function CreateForm() {
    const actions = useActions();
    const [createSurvey, {isPending, errorMsg}] = useAsyncCallback(actions.createSurvey);

    // make sure to disable this form if we cannot create more surveys
    const [surveys] = useSurveyList();
    const {surveysLimit} = useCapFlags();
    const cannotCreateMoreSurveys = surveysLimit !== null && surveys.length >= surveysLimit;

    const breadcrumbs = [
        { to: '/', text: 'Surveys' },
        { text: 'New Survey' }
    ];

    return (<>
        {cannotCreateMoreSurveys && <Redirect to="/survey"/>}
        <SurveyForm
            isPending={isPending}
            onSubmit={createSurvey}
            errorMsg={errorMsg}
            navCrumbs={breadcrumbs}/>
    </>);
}

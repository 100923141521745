import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import PriceCard from './PriceCard';

import useToggle from '../../hooks/useToggle';
import useUserInfo from '../../hooks/useUserInfo';
import useAsyncCallback from '../../hooks/useAsyncCallback';

import freePlanLogo from './leaf.svg';        // https://www.flaticon.com/free-icon/plant_704129
import teamPlanLogo from './monitor.svg';     // https://www.flaticon.com/free-icon/growth_1516861?
import businessPlanLogo from './startup.svg'; // https://www.flaticon.com/free-icon/startup_831763

import {formatAsMoney} from '../../common/formatcurrency';


const plans = [
    {
        icon: freePlanLogo,
        title: window.PADDLE_CONFIG.plans[0].title,
        billed: window.PADDLE_CONFIG.plans[0].billed,
        blurb: "Good for a small team of 5",
        perks: [
            '1 team',
            'Up to 5 respondants per survey',
            'Up to 10 pulse surveys',
            'Analytics dashboards',
            'Sharable Niko-Niko calendar'
        ]
    },
    {
        icon: teamPlanLogo,
        title: window.PADDLE_CONFIG.plans[1].title,
        billed: window.PADDLE_CONFIG.plans[1].billed,
        blurb: "Ideal for a team of up to 20",
        perks: [
            'Everything in Starter plan, plus...',
            'Up to 20 respondants per survey',
            'Up to 50 pulse surveys',
            'Anonymity on or off',
        ]
    },
    {
        icon: businessPlanLogo,
        title: window.PADDLE_CONFIG.plans[2].title,
        billed: window.PADDLE_CONFIG.plans[2].billed,
        blurb: "Perfect for multiple and larger teams",
        perks: [
            'Everything in Team plan, plus...',
            'Multiple teams',
            'Unlimited respondants',
            'Unlimited pulse surveys',
            'Data exports',
            'SAML Single-Sign-On (coming soon)'
        ]
    }
];

const planRanks = [
    ...(plans.map(p => p.billed.monthly.planID)),
    ...(plans.map(p => p.billed.annually.planID)),
]

function getTaxType(country) {
    if (!country) { return 'Tax'; }
    switch (country) {
        case "AU":
        case "IN":
        case "SG":
        case "NZ":
        case "MD":
            return "GST";
        case "CA":
            return "QST";
        case "JP":
            return "Consumption Tax";
        case "US":
            return "Sales Tax";
        default:
            return "VAT"
    }
}

const usePaddleDialogStyles = makeStyles((theme) => ({
    loading: {
        '& .checkout-container': {
            visibility: 'hidden'
        },
        backgroundColor: theme.palette.grey[100]
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    price: {
        fontSize: '1.3em',
        color: theme.palette.primary.light
    },
    successScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#06C668',
    },
    successIcon: {
        fontSize: '80pt'
    },
    receipt: {
        marginTop: theme.spacing(5)
    },
    finishButton: {
        marginTop: theme.spacing(5),
        width: '100%'
    }
}));

function PaddleDialogSuccess({receiptURL, onClose}) {
    const classes = usePaddleDialogStyles();

    return (<div className={classes.successScreen}>
        <CheckCircleOutlineIcon className={classes.successIcon} />
        <Typography variant="h4">Purchase Successful</Typography>

        {receiptURL
            ? <Typography variant="body1" color="textSecondary" className={classes.receipt}><a href={receiptURL} target="_blank" rel="noopener noreferrer">View Receipt</a></Typography>
            : <Typography variant="body1" color="textSecondary" className={classes.receipt}>[Loading Receipt]</Typography>
        }
        <Typography variant="body1" color="textPrimary">A copy of the receipt has been emailed to you.</Typography>
        
        <Button className={classes.finishButton} variant="contained" color="primary" onClick={onClose}>Close</Button>
    </div>);
}

function PaddleDialog({open, plan, email, onClose}) {
    const classes = usePaddleDialogStyles();

    const [isLoading, setIsLoading] = React.useState(true);
    const [isComplete, setIsComplete] = React.useState(false);

    const [planName, setPlanName] = React.useState(null);
    const [{currency, total, tax, interval}, setPrice] = React.useState({});
    const [country, setCountry] = React.useState(null);

    const [getReceiptURL, {result:receiptURL}, resetReceiptURL] = useAsyncCallback(checkoutId => new Promise((resolve) => {
        window.Paddle.Order.details(checkoutId, data => {
            resolve(data.order.receipt_url);
        }, false);
    }));

    React.useEffect(() => {
        if (open) {
            setIsLoading(true);
            setIsComplete(false);
            setPlanName(null);
            setPrice({});
            setCountry(null);
            resetReceiptURL();
        }
    }, [open]);

    if (!open || !plan || !email) {
        return null;
    }
    
    const onEntered = () => {
        window.Paddle.Options({
            eventCallback: (data) => {
                setPlanName(data.eventData.product.name);

                if (data.eventData.country) {
                    setCountry(data.eventData.country);
                }

                if (data.eventData.checkout.recurring_prices) {
                    setPrice({
                        currency: data.eventData.checkout.recurring_prices.customer.currency,
                        total: Number(data.eventData.checkout.recurring_prices.customer.total),
                        tax: Number(data.eventData.checkout.recurring_prices.customer.total_tax),
                        interval: data.eventData.checkout.recurring_prices.interval.type
                    });
                }
            }
        });

        window.Paddle.Checkout.open({
            product: plan.planID,
            disableLogout: true,
            email: email,
            method: 'inline',
            frameTarget: 'checkout-container',
            frameInitialHeight: 521,
            frameStyle: 'width:100%; min-width:495px; background-color: transparent; border: none;',
            loadCallback: () => setIsLoading(false),
            successCallback: (data) => {
                setIsComplete(true);
                getReceiptURL(data.checkout.id);
            }
        });
    };

    return (
        <Dialog open onEntered={onEntered} fullWidth>
            <DialogTitle>
                Purchase: {planName}
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose(isComplete)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={isLoading ? classes.loading : null}>
                {!isComplete && country && currency &&
                    <Typography variant="body1" align="center">
                        Total price is <span className={classes.price}>{formatAsMoney(total, country, currency)}</span> / {interval}
                        {tax > 0 && <Typography variant="body2" color="textSecondary">
                            inc. {(tax / (total - tax) * 100).toFixed(2)}% {getTaxType(country)} ({formatAsMoney(tax, country, currency)})
                        </Typography>}
                    </Typography>
                }
                <div className="checkout-container"></div>
                {isComplete && <PaddleDialogSuccess receiptURL={receiptURL} onClose={() => onClose(isComplete)}/>}
                {isLoading ? <CircularProgress className={classes.progress} color="primary" /> : null}
            </DialogContent>
        </Dialog>
    );
}

const usePageStyles = makeStyles(() => ({
    alert: {
        marginBottom: '1em'
    },
    credit: {
        fontSize: '0.5em',
        opacity: '0.7',
        textAlign: 'center',
        '& a': {
            textDecoration: 'none'
        }
    },
    planDurationToggle: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& p': {
            width: '11em',
            '&:first-child': {
                textAlign: 'right'
            }
        }
    }
}));


export default function PricingPlans({paddlePlanID, loading, onStartPlan, onChangePlan}) {
    const classes = usePageStyles();

    const user = useUserInfo();
    const planRank = planRanks.indexOf(paddlePlanID);

    const [dialogOpen, openDialog, closeDialog] = useToggle(false);
    const [newPlan, setNewPlan] = React.useState(null);

    const [isAnnual, setIsAnnual] = React.useState(true);
    React.useEffect(() => {
        setIsAnnual(planRank == -1 || planRank >= plans.length);
    }, [planRank])

    function openCheckout(plan) {
        setNewPlan(plan);
        openDialog();
    }

    function handleClose(isComplete) {
        closeDialog();
        if (isComplete) {
            onStartPlan(newPlan);
        }
    }

    const handleDurationChange = (event) => {
        setIsAnnual(event.target.checked);
    };

    return (<>
        <PaddleDialog open={dialogOpen} plan={newPlan} email={user.email} onClose={handleClose} />

        <div className={classes.planDurationToggle}>
            <Typography variant="body1">Pay Monthly</Typography>
            <Switch
                color="primary"
                checked={isAnnual}
                onChange={handleDurationChange}
            />
            <Typography variant="body1">Pay Yearly (Save {window.PADDLE_CONFIG.annualDisount})</Typography>
        </div>

        <Grid container spacing={5} alignItems="flex-end">
            {
                plans
                    .map((p, idx) => ({
                        ...p,
                        ...(isAnnual ? p.billed.annually : p.billed.monthly),
                        rank: idx + (isAnnual ? plans.length : 0)
                    }))
                    .map((p, idx) => (
                    <Grid key={idx} item sm={12} md={12/plans.length}>
                        <PriceCard
                            icon={p.icon}
                            title={p.title}
                            blurb={p.blurb}
                            price={p.price}
                            perPeriod="USD / Month"
                            billingFrequency={isAnnual ? `(Billed Annually)` : '(Billed Monthly)'}
                            perks={p.perks}
                            isLoading={loading}
                            isDowngrade={p.rank < planRank}
                            isUpgrade={p.rank > planRank && planRank !== -1}
                            isActive={p.rank === planRank}
                            onBuy={() => openCheckout(p)}
                            onChangePlan={() => onChangePlan(p)} />
                    </Grid>
                ))
            }
        </Grid>

        <p className={classes.credit}>Icons made by <a href="https://www.flaticon.com/authors/smashicons">Smashicons</a> from <a href="https://www.flaticon.com">www.flaticon.com</a></p>
    </>);
}

export default function getPlanByID(paddlePlanID, isOnTrial) {
    if (paddlePlanID) {
        const plan = window.PADDLE_CONFIG.plans.find(p => p.billed.monthly.planID === paddlePlanID || p.billed.annually.planID === paddlePlanID);
        const p = plan ? plan : window.PADDLE_CONFIG.fallback;
        return p;
    }

    if (isOnTrial) {
        return window.PADDLE_CONFIG.trial;
    }

    return window.PADDLE_CONFIG.minimal;
}
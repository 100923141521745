import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Logo from '../../components/Logo';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        background: 'rgb(105,87,247)',
        background: 'radial-gradient(circle, rgba(105,87,247,1) 0%, rgba(95,77,238,1) 65%, rgba(79,63,209,1) 100%)',
        minHeight: '100vh'
    },
    centeringContainer: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        boxShadow: '0 0 72px 15px rgba(255,255,255,0.2), 0 0 10px 0 rgb(0,0,0,0.25) inset;',
        borderRadius: '10px',
        padding: '20px',
        marginBottom: '60px' // offset for the icon to maintain vertical centrality
    },
    logo: {
        width: '250px',
        height: 'auto',
        padding: '20px'
    }
}));

export default function WebsiteThemeContainer({page}) {
    const classes = useStyles();

    return (<>
        <div className={classes.container}>
            <a href="https://teamfeels.app"><Logo className={classes.logo} /></a>
            <div className={classes.centeringContainer}>
                <Paper className={classes.paper}>
                    {page}
                </Paper>
            </div>
        </div>
    </>);
}

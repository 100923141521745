import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAsyncCallback from './useAsyncCallback';
import { actions } from '../actions';

export default function useSetupStatus() {
    const [loadIntegrations, {isFinished}] = useAsyncCallback(actions.loadIntegrations());
    const {needsSlackConfiguring, needsChannelInvite, trialExpired, isOnPaymentPlan} = useSelector(state => state.setupstatus);

    // load on first mount
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadIntegrations);
    }, []);

    return {
        isChecking: !isFinished,
        needsSlackConfiguring,
        needsChannelInvite,
        trialExpired,
        isOnPaymentPlan
    };
}
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { validatePassword, validatePasswordPair } from '../../../common/validatepassword';


const INITIAL_STATE = {
    oldPassword: '',
    password1: '',
    password2: '',
    showAsError: false
};

export default function ChangePasswordDialog({open, onClose, onSubmit}) {
    const [oldPassword, setOldPassword] = React.useState(INITIAL_STATE.oldPassword);
    const [password1, setPassword1] = React.useState(INITIAL_STATE.password1);
    const [password2, setPassword2] = React.useState(INITIAL_STATE.password2);
    const [showAsError, setShowAsError] = React.useState(INITIAL_STATE.showAsError);

    const oldPasswordValidity = validatePassword(oldPassword);
    const newPasswordValidity = validatePasswordPair(password1, password2);
    const oldAndNewAreSame = password1 !== '' && oldPassword === password1;
    const password1Msg = oldAndNewAreSame ? 'Password must be different from old password' : newPasswordValidity.password1.msg;
    const password2Msg = newPasswordValidity.password2.msg;

    const disableSubmit = oldPassword === '' || password1 === '' || password2 === '';
    const formIsValid = oldPasswordValidity.valid && newPasswordValidity.allValid && !oldAndNewAreSame && !disableSubmit;

    const handleOldPasswordChange = (event) => setOldPassword(event.target.value);
    const handlePassword1Change = (event) => setPassword1(event.target.value);
    const handlePassword2Change = (event) => setPassword2(event.target.value);

    React.useEffect(() => {
        if (!open) { // reset state on close
            setOldPassword(INITIAL_STATE.oldPassword);
            setPassword1(INITIAL_STATE.password1);
            setPassword2(INITIAL_STATE.password2);
            setShowAsError(INITIAL_STATE.showAsError);
        }
    }, [open]);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (!formIsValid) {
            setShowAsError(true);
            return;
        }

        onSubmit(oldPassword, password1);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            <form onSubmit={handleFormSubmit}>
                <DialogTitle>Change Your Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter your current password and the new password you want to use.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Existing Password"
                        type="password"
                        error={showAsError && !oldPasswordValidity.valid}
                        helperText={showAsError ? oldPasswordValidity.msg : null}
                        onChange={handleOldPasswordChange}
                    />
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="New Password"
                        type="password"
                        error={showAsError && !!password1Msg}
                        helperText={password1Msg || ' '}
                        onChange={handlePassword1Change}
                    />
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="New Password (Again)"
                        type="password"
                        error={showAsError && !!password2Msg}
                        helperText={password2Msg || ' '}
                        onChange={handlePassword2Change}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">Cancel</Button>
                    <Button type="submit" disabled={disableSubmit} color="primary">Submit</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import SpinnerButton from '../../components/SpinnerButton';
import ErrorBanner from '../../components/ErrorBanner';
import InvalidURLParamsScreen from '../../components/InvalidURLParamsScreen';

import useAsyncCallback from '../../hooks/useAsyncCallback';

import * as auth from '../../api/auth';
import parseQueryParams from '../../common/parsequeryparams';



const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    header: {
        marginBottom: theme.spacing(2)
    },
    name: {
        color: theme.palette.success.dark,
        fontWeight: 'bold',
        fontSize: '1.2em'
    },
    email: {
        textAlign: 'center',
        fontFamily: 'monospace',
        fontSize: '1.5em'
    },
    resendButton: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    proceedAnywayButton: {
        opacity: 0.6,
        fontSize: '0.75em',
        position: 'absolute',
        bottom: theme.spacing(2)
    }
}));

export default function CheckYourInbox({isNewSignup}) {
    const classes = useStyles();

    // extract query params from URL
    const {name, email} = parseQueryParams();
    const unknownUsername = name == null; // this happens when an unconfirmed user tries to login, we don't know their nickname

    const [resendEmail, {isPending, errorMsg}] = useAsyncCallback(isNewSignup ? auth.resendSignupVerification : auth.resendEmailChangeVerification);
    
    const handleResendEmail = () => {
        if (isNewSignup) {
            resendEmail(email);
        } else {
            resendEmail();
        }
    }

    if (!email) {
        return <InvalidURLParamsScreen/>;
    }

    const Name = (<span className={classes.name}>{name}</span>);

    return (
        <Container className={classes.container} maxWidth="sm">
            <Avatar className={classes.avatar}>
                {isNewSignup ? <CakeIcon /> : <EmailIcon />}
            </Avatar>
            {isNewSignup ?
                <>
                    <Typography component="h1" variant="h4" className={classes.header}>Welcome {unknownUsername ? 'Back' : Name}!</Typography>
                    <Typography component="h2" variant="h5" className={classes.header}>We just need to verify your email address.</Typography>
                </>
             :
                <>
                    <Typography component="h1" variant="h5" className={classes.header}>Email Verification Needed</Typography>
                    <Typography align="center">{Name}, the email address we have for you is <b>not yet verified.</b></Typography>
                </>
            }
            <Typography>Please click the {isNewSignup ? 'confirmation' : 'verification'} link in the email we have sent to:</Typography>
            <Typography className={classes.email}>{email}</Typography>
            <SpinnerButton pending={isPending} color="primary" className={classes.resendButton} onClick={handleResendEmail}>
                Email Not Received? Send Again
            </SpinnerButton>
            <ErrorBanner text={errorMsg} />
            {isNewSignup ? null :
                <Button
                    disabled={isPending}
                    color="secondary"
                    className={classes.proceedAnywayButton}
                    component={Link}
                    to='/'>
                    Click To Proceed Without Verification (Not Recommended)
                </Button>
            }
        </Container>
    );
}

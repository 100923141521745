import React from 'react';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    '@keyframes expand': {
        from: {
          transform: 'scale(1, 0)',
          opacity: 0
        }
    },
    banner: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
        padding: theme.spacing(2) + 'px',
        display: 'flex',
        alignItems: 'center',
        animation: '$expand 0.1s ease-out'
        
    },
    icon: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },

}));

export default function ErrorBanner({text, className, ...other}) {
    const styles = useStyles();

    if (!text) { return null; }

    return (
        <Paper className={`${styles.banner} ${className}`} elevation={5} {...other}>
            <ErrorIcon className={styles.icon} />
            <Typography>{text}</Typography>
        </Paper>
    );
}
import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
    flexWrapper: {
        display: 'flex',
        height: '100%'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: lighten(theme.palette.grey[50], 0.5),
        flexShrink: '0'
    },
    content: {
        padding: theme.spacing(3, 6),
        flexGrow: '1'
    }
}));

export default function VerticalTabs({tabs, tabKey, onChange, ...props}) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        onChange(newValue);
    };

    return (
        <Paper {...props}>
            <div className={classes.flexWrapper}>
                <Tabs
                    orientation="vertical"
                    value={tabKey}
                    onChange={handleChange}
                    className={classes.tabs}
                    textColor="primary"
                    indicatorColor='primary'
                >
                    {tabs.map(t => <Tab key={t.key} value={t.key} label={t.title} icon={t.icon}/>)}
                </Tabs>

                {tabs.map(t => (
                    <div key={t.key} hidden={t.key !== tabKey} className={classes.content}>
                        {t.content}
                    </div>
                ))}
            </div>
        </Paper>
    );
}
import React from 'react';
import { useParams, Link } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import PollIcon from '@material-ui/icons/Poll';
import EditIcon from '@material-ui/icons/Edit';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import DateRangePaginator from '../../components/DateRangePaginator';
import PageHeadingBar from '../../components/PageHeadingBar';
import ErrorBanner from '../../components/ErrorBanner';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';

import useSurvey from '../../hooks/useSurvey';
import useSharedSurvey from '../../hooks/useSharedSurvey';
import useReplies from '../../hooks/useReplies';
import useToggle from '../../hooks/useToggle';

import MoreOptionsMenu from './MoreOptionsMenu';
import ShareButton from './ShareButton';
import Overview from './Overview';
import Trends from './LongTermTrends';

import useActions from '../../hooks/useActions';
import useCapFlags from '../../hooks/useCapFlags';

import moment from 'moment';


const usePageControls = makeStyles(theme => ({
    controls: {
        backgroundColor: '#fff',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.03)'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > *:last-child': {
            flexGrow: 1,
            textAlign: 'right'
        }
    }
}));

function PageControls({activePage, onChangePage, children}) {
    const classes = usePageControls();

    return (<div className={classes.controls}>
        <Container className={classes.container}>
            <div>
                <Tabs
                    value={activePage}
                    onChange={onChangePage}
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab label="Results Dashboard" />
                    <Tab label="Trend Analysis" />
                </Tabs>
            </div>
            <div>
                {children}
            </div>
        </Container>
    </div>);
}

function ActionButtons({surveyID, onDelete, onExportToCSV}) {
    const shareBtnRef = React.useRef();

    return (<>
        <ShareButton surveyId={surveyID} btnRef={shareBtnRef}/>
        <IconButton component={Link} to={'/edit/' + surveyID}>
            <EditIcon/>
        </IconButton>
        <MoreOptionsMenu surveyID={surveyID} shareBtnRef={shareBtnRef} onExportToCSV={onExportToCSV} onDelete={onDelete}/>
    </>);
}

const useTimeRangeControlStyles = makeStyles(theme => ({
    toggleButton: {
        height: '35px' // default is 40px
    }
}));

function TimeRangeControls({value, onChange}) {
    const classes = useTimeRangeControlStyles();

    const handleChange = (event, value) => {
        if (value != null) {
            onChange(value);
        }
    }

    return (
        <ToggleButtonGroup
            size="small"
            exclusive
            value={value}
            onChange={handleChange}
        >
            <ToggleButton className={classes.toggleButton} value="week">Week</ToggleButton>
            <ToggleButton className={classes.toggleButton} value="month">Month</ToggleButton>
            <ToggleButton className={classes.toggleButton} value="recent">Last 10</ToggleButton>
        </ToggleButtonGroup>
    );
}

const useResultsDashboardstyles = makeStyles(theme => ({
    headerBar: {
        backgroundColor: '#eaecff',
        '& .MuiTypography-colorTextSecondary': {
            color: '#5e6486'
        },
        paddingTop: '5px'
    },
    avatar: {
        backgroundColor: '#4b5bb5',
        width: '2.3em',
        height: '2.3em',
        boxShadow: '6px 6px 12px #c5cae2, -6px -6px 12px #fbffff'
    },
    overview: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '10px -20px 20px -20px'
    }
}));

function useFakeData() {
    const [data, setData] = React.useState([]);

    const emojis = ['😁', '🙂', '😑', '🙁', '😩'];
    let idx = 21;

    React.useEffect(() => {
        async function nested() {
            const namesAndGendersURL = 'https://raw.githubusercontent.com/pixelastic/fakeusers/master/data/final.json';
            const resp = await fetch(namesAndGendersURL);
            const json = await resp.json();

            const users = [];

            for (let i = 0; i < 98; i++) {
                const person = json[i + 50];
                const item = {
                    slackUserId: i,
                    name: person.first_name.charAt(0).toUpperCase() + person.first_name.slice(1),
                    avatar: `https://avatars.dicebear.com/api/${person.gender}/${i}.svg`,
                    responses: []
                };

                for (let day = 0; day < 365; day++) {
                    idx = idx * (i+3) * (day + 7) * 11;
                    idx = idx % 435453 + 7;
                    idx = idx % (emojis.length * 30);
                    idx += 3;
                    const e = emojis[idx % emojis.length];
                    item.responses.push({timestamp: Date.now()-(day * 24*60*60*1000), choice: e});
                }

                users.push(item);
            }

            setData(users);
        }

        nested();
    }, []);

    
    return [data, data.length, data.length, false, null];
}

function BaseSurveyDashboard({isShared}) {
    const classes = useResultsDashboardstyles();

    const {deleteSurvey, exportToCSV} = useActions();
    const {enablesDataExports} = useCapFlags();

    const {surveyID} = useParams();
    const useSurveyHook = isShared ? useSharedSurvey : useSurvey;
    const [survey, isLoadingSurvey, surveyLoadErrorMsg] = useSurveyHook(surveyID);
    const [{userReplies, limitedToRespondantsNum, originalNumberOfRespondants}, isLoadingReplies, repliesLoadErrorMsg] = useReplies(survey, isShared);
    //const [userReplies, limitedToRespondantsNum, originalNumberOfRespondants, isLoadingReplies, repliesLoadErrorMsg] = useFakeData();

    const [activePage, setActivePage] = React.useState(0);
    const [timeUnit, setTimeUnit] = React.useState('week');
    const [startDate, setStartDate] = React.useState(moment().startOf(timeUnit).valueOf());
    const daysInPeriod = timeUnit === 'week' ? 7
                       : timeUnit === 'recent' ? 10
                       : moment(startDate).daysInMonth();

    // effect to pick a suitable initial time-unit view based on the survey
    React.useEffect(() => {
        if (!survey || !survey.config) { return; }

        const defaultViewForSurvey = {
            'weekly': 'week',
            'multiweek': 'month',
            'monthly': 'month',
            'never': 'recent'
        }[survey.config.repeat.mode] || 'recent';
        setTimeUnit(defaultViewForSurvey);
    }, [survey]);

    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useToggle(false);

    const handleChangePage = (event, newPageIdx) => {
        if (newPageIdx === 0) { // reset to current week/month
            setStartDate(moment().startOf(timeUnit).valueOf());
        }
        setActivePage(newPageIdx);
    };
    
    const handleDelete = () => { deleteSurvey(surveyID); };
    const handleExport = (isShared || !enablesDataExports) ? null : () => {
        if (survey && userReplies) {
            exportToCSV(survey.config.title, userReplies);
        }
    }
    
    const surveyTitle = survey ? survey.config.title : 'Loading...';
    const surveyQuestion = survey ? survey.config.question : '...';

    const navCrumbs = isShared ? null : [
        { to: '/', text: 'Surveys' },
        { text: '' }
    ];

    const tabContents = [
        {
            controls:
                <>
                    {timeUnit != 'recent' && <DateRangePaginator timestamp={startDate} timeUnit={timeUnit} onChange={setStartDate} /> }
                    <TimeRangeControls value={timeUnit} onChange={setTimeUnit} />
                </>,
            page:   
                <Overview survey={survey} users={userReplies} startDate={startDate} timeUnit={timeUnit} maxReplyColumns={daysInPeriod} isLoadingReplies={isLoadingReplies || isLoadingSurvey} />
        },
        {
            controls:
                <>
                </>,
            page:
                <Trends users={userReplies} isLoadingReplies={isLoadingReplies} />
        }
    ];

    return (<>
        <PageHeadingBar navCrumbs={navCrumbs} />
        <CardHeader
            className={classes.headerBar}
            avatar={
                <Avatar className={classes.avatar} variant="rounded">
                    <PollIcon fontSize="large" />
                </Avatar>
            }
            action={isShared ? null : <ActionButtons surveyID={surveyID} onDelete={openDeleteDialog} onExportToCSV={handleExport}/>}
            title={<Typography variant="h6" component="h3">{surveyTitle}</Typography>}
            subheader={'"' + surveyQuestion + '"'}
        />

        <ErrorBanner text={surveyLoadErrorMsg} />
        <ErrorBanner text={repliesLoadErrorMsg} />

        <PageControls activePage={activePage} onChangePage={handleChangePage}>
            {tabContents[activePage].controls}
        </PageControls>

        {originalNumberOfRespondants > limitedToRespondantsNum &&
            <Alert severity="warning">
                <AlertTitle>Upgrade to view all results!</AlertTitle>
                <Typography variant="body2">This survey received <strong>{originalNumberOfRespondants} unique respondants</strong> since it was created but your subscription plan limits this page to show only the <strong>{limitedToRespondantsNum} most recent</strong> respondants.</Typography>
                <Typography variant="body2"><strong><Link to="/account/subscription">Upgrade your plan now to view them all</Link></strong></Typography>
            </Alert>
        }

        {tabContents[activePage].page}

        <DeleteConfirmationDialog what={surveyTitle} open={isDeleteDialogOpen} onClose={closeDeleteDialog} onConfirm={handleDelete} />
    </>);
}

export function SharedSurveyDashboard() {
    return <BaseSurveyDashboard isShared />
}

export function SurveyDashboard() {
    return <BaseSurveyDashboard />
}


import { combineReducers } from 'redux';
import userInfo from './userinfo';
import setupstatus from './setupstatus';
import mailinglist from './mailinglist';
import subscription from './subscription';
import integrations from './integrations';
import surveys from './surveys';

export default combineReducers({
    userInfo,
    setupstatus,
    mailinglist,
    subscription,
    integrations,
    surveys
});
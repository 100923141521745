import React from 'react';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { TransitionGroup } from 'react-transition-group'
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../../components/PageTitle';
import ErrorBanner from '../../components/ErrorBanner';
import SurveyItem from './SurveyItem';
import NoSurveysYet from './NoSurveysYet';
import TagFilter from './TagFilter';

import useActions from '../../hooks/useActions';
import useSurveyList from '../../hooks/useSurveyList';
import useCapFlags from '../../hooks/useCapFlags';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(5),
        position: 'relative'
    },
    header: {
        '& >:first-child': {
            flexGrow: 1
        },
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3)
    },
    tagFilter: {
        width: '25%',
        marginRight: theme.spacing(5)
    },
    newSurveyButton: {
        marginRight: theme.spacing(2)
    },
    popover: {
        padding: theme.spacing(4),
        textAlign: 'center'
    },
}));

const useLoadingStyles = makeStyles(theme => ({
    skeleton: {
        marginTop: theme.spacing(2)
    },
    spinner: {
        position: 'absolute',
        top: '130px',
        left: '50%',
        marginLeft: '-20px'
    },
    gradient: {
        marginTop: theme.spacing(14),
        maskImage: 'linear-gradient(to bottom, black, transparent)'
    }
}));

function Loading() {
    const classes = useLoadingStyles();

    return (<>
        <CircularProgress className={classes.spinner}/>
        <div className={classes.gradient}>
            <Skeleton variant="rect" height={80} className={classes.skeleton}/>
            <Skeleton variant="rect" height={80} className={classes.skeleton}/>
            <Skeleton variant="rect" height={80} className={classes.skeleton}/>
            <Skeleton variant="rect" height={80} className={classes.skeleton}/>
            <Skeleton variant="rect" height={80} className={classes.skeleton}/>
        </div>
    </>);
}

export default function Surveys() {
    const classes = useStyles();
    const history = useHistory();

    const [tagsToFilterBy, setTagsToFilterBy] = React.useState([]);

    const {deleteSurvey} = useActions();
    const [surveys, isLoading, error] = useSurveyList();
    const {surveysLimit} = useCapFlags();

    const noSurveysCreated = surveys.length === 0;
    const cannotCreateMoreSurveys = surveysLimit !== null && surveys.length >= surveysLimit;

    const filteredSurveys = React.useMemo(
        () => tagsToFilterBy && tagsToFilterBy.length > 0
                ? surveys.filter(s => s.config.tags.some(t => tagsToFilterBy.includes(t)))
                : surveys
        , [tagsToFilterBy, surveys]
    );
        
    const allTags = React.useMemo(
        () => [...new Set(surveys.flatMap(s => s.config.tags))]
        , [surveys]
    );
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleNewSurveyClick = (event) => {
        if (cannotCreateMoreSurveys) {
            setAnchorEl(event.currentTarget);
        } else {
            history.push('/add');
        }
    };


    const listItems = noSurveysCreated ? <NoSurveysYet/> :
    (
        <TransitionGroup>
            {filteredSurveys.map(s => 
                <SurveyItem
                    key={s.id}
                    survey={s}
                    onDelete={deleteSurvey}/>)
            }
        </TransitionGroup>
    );

    return (
        <>
            <Container className={classes.container}>
                {isLoading || noSurveysCreated ? null :
                    <div className={classes.header}>
                        <PageTitle><QuestionAnswerIcon/> Pulse Surveys</PageTitle>
                        <TagFilter tags={allTags} onChange={setTagsToFilterBy} className={classes.tagFilter} />
                        <Fab variant="extended" color="secondary" className={classes.newSurveyButton} onClick={handleNewSurveyClick}>
                            <AddIcon/>
                            New Survey
                        </Fab>
                        <Popover
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            classes={{
                                paper: classes.popover
                            }}
                        >
                            <Typography variant="h5">You're maxed out! 🤯</Typography>
                            <Typography variant="body1">Your subscription plan lets you create up to {surveysLimit} pulse surveys.</Typography>
                            <Typography variant="body1"><Link to="/account/subscription">Upgrade your plan if you need more!</Link></Typography>
                        </Popover>
                    </div>
                }
                
                {isLoading ? <Loading/> : listItems}
                <ErrorBanner text={error} />
            </Container>
        </>
    );
}
import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const StyledToggleButtonGroup = withStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'initial'
    },
    grouped: {
        margin: theme.spacing(0.5),
        flexGrow: 1,
        height: '3em',
        '&:not(:first-child)': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '20px',

            // these are to 'undo' some weird styles applied
            marginLeft: '-1px !important',
            borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
        },
        '&:first-child': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '20px'
        }
    },
}))(ToggleButtonGroup);


const StyledToggleButton = withStyles((theme) => ({
    root: {
        "&$selected": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            
        },
        '&$selected$disabled': {
            opacity: 0.2
        },
        "&$selected:hover": {
            backgroundColor: theme.palette.primary.main,
            opacity: 0.8
        },
        "&:hover": {
            backgroundColor: theme.palette.grey
        }
    },
    selected: {},
    disabled: {}
}))(ToggleButton);


export default function DayOfWeekPicker({value, onChange, allowMultiple, disabled}) {
    const getSelection = (arr) => {
        if (allowMultiple) {
            return arr;
        } else {
            return arr.length === 0 ? null : arr.slice(-1)[0];
        }
    }

    const handleSetDaysOfWeek = (event, newDaysOfWeek) => {
        onChange(getSelection(newDaysOfWeek));
    };

    return (
        <StyledToggleButtonGroup value={allowMultiple || value == null ? value : [value]} onChange={handleSetDaysOfWeek}>
            <StyledToggleButton value="sun" disabled={disabled}>Sun</StyledToggleButton>
            <StyledToggleButton value="mon" disabled={disabled}>Mon</StyledToggleButton>
            <StyledToggleButton value="tue" disabled={disabled}>Tue</StyledToggleButton>
            <StyledToggleButton value="wed" disabled={disabled}>Wed</StyledToggleButton>
            <StyledToggleButton value="thu" disabled={disabled}>Thu</StyledToggleButton>
            <StyledToggleButton value="fri" disabled={disabled}>Fri</StyledToggleButton>
            <StyledToggleButton value="sat" disabled={disabled}>Sat</StyledToggleButton>
        </StyledToggleButtonGroup>
    );
}

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';

import Chart from 'chart.js';
import 'chartjs-plugin-zoom';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { ColorBlind10 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

// custom plugin to apply the colour scheme to the hover (because chartjs-plugin-colorschemes doesn't do this)
const ColorSchemesHoverPlugin = {
    beforeUpdate: (chart, options) => {
        chart.config.data.datasets.forEach((dataset, datasetIndex) => {
            dataset.hoverBorderColor = Chart.helpers.color(dataset.borderColor).alpha(0.3).rgbString();
        });
    }
};
Chart.plugins.register(ColorSchemesHoverPlugin);

const useTrendGraphCardStyles = makeStyles(theme => ({
    card: {
        padding: '8px',
        flexBasis: 0,
        flexShrink: 1,
        flexGrow: 1,
        minWidth: '300px',
        height: '500px',
        margin: '20px',
        marginTop: '10px',
        boxShadow: '0 1px 20px 0 rgba(0,0,0,.08)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -20,
        marginLeft: -20,
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        display: 'inline',
        marginRight: '0.3em'
    }
}));

function TrendGraphCard({users}) {
    const classes = useTrendGraphCardStyles();

    const [timePeriod, setTimePeriod] = React.useState('month');

    const noData = !users || users.length === 0;

    return (
        <Paper className={classes.card}>
            {noData && <Typography>Nothing to see here yet!</Typography>}
            {!noData && <>
                <div className={classes.controls}>
                    <Typography variant="button" color="textSecondary">Past Year</Typography>
                    <div>
                        <InputLabel className={classes.label}>Granularity:</InputLabel>
                        <Select disableUnderline autoWidth value={timePeriod} onChange={event => setTimePeriod(event.target.value)}>
                            <MenuItem value="month">Per Month</MenuItem>
                            <MenuItem value="week">Per Week</MenuItem>
                            <MenuItem value="day">Per Day</MenuItem>
                        </Select>
                    </div>
                </div>
                
                {<LineGraph users={users} timePeriod={timePeriod} />}
            </>}
        </Paper>
    );
}

function LineGraph({users, timePeriod}) {
    const canvasRef = React.useRef();
    const chartRef = React.useRef();

    const color = '#d4d9f3bb';

    React.useEffect(() => {
        // no data then no chart
        if (!users || users.length === 0) { return; }

        const {labels, datasets} = calcTimeseriesDatasets(users, timePeriod);

        // update chart if it already exists
        if (chartRef.current) {
            chartRef.current.data.labels = labels;
            chartRef.current.data.datasets = datasets;
            chartRef.current.options.plugins.zoom.zoom.sensitivity = timePeriod === 'day' ? 0 : 3;
            chartRef.current.update();
            return;
        }

        // create chart (only happens once)
        chartRef.current = new Chart(canvasRef.current, {
            type: 'line',
            data: {
                labels: labels,
                datasets
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                    labels: {
                        fontSize: 32,
                        boxWidth: 6,
                        padding: 40,
                        usePointStyle: true
                    },
                    
                },
                hover: {
                    mode: 'dataset',
                    intersect: false
                },
                elements: {
                    point: {
                        hitRadius: 10,
                        radius: 0,
                        hoverRadius: 3
                    },
                    line: {
                        borderWidth: 2,
                        hoverBorderWidth: 2,
                        hoverBorderColor: undefined,
                        borderCapStyle: 'round',
                        hoverBorderCapStyle: 'round'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                },
                tooltips: {
                    intersect: false,
                    displayColors: false,
                    backgroundColor: '#3f51b5dd',
                    bodyFontStyle: 'bold',
                    bodyFontSize: 22,
                    callbacks: {
                        label: (tooltipItem, data) => data.datasets[tooltipItem.datasetIndex].label + ' ' + tooltipItem.value + 'x'
                    }
                },
                plugins: {
                    datalabels: {
                        display: false
                    },
                    colorschemes: {
                        scheme: ColorBlind10,
                        override: true
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x'
                        },
                        zoom: {
                            enabled: true,
                            mode: 'x',
                            speed: 1
                        }
                    }
                }
            }
        });
    }, [users, timePeriod]);

    return (
        <div style={{position:'relative', flexGrow:1}}>
            <canvas ref={canvasRef} width="1" height="1"></canvas>
        </div>
    );
}


function calcTimeseriesDatasets(users, timePeriod) {
    const toAxisValue = ts => moment(ts).startOf(timePeriod).format('D MMM') + ' - ' + moment(ts).endOf(timePeriod).format('D MMM');

    const resultCountByTimestampByChoice = {};
    users.forEach(u => {
        u.responses.forEach(r => {
            const timestamp = toAxisValue(r.timestamp);
            
            const resultCountByTimestamp = resultCountByTimestampByChoice[r.choice] || {};
            const resultCount = resultCountByTimestamp[timestamp] || 0;
            resultCountByTimestamp[timestamp] = resultCount + 1;
            resultCountByTimestampByChoice[r.choice] = resultCountByTimestamp;
        });
    });

    const timestamps = users.flatMap(u => u.responses).map(r => r.timestamp).sort();
    const labels = [...new Set(timestamps.map(toAxisValue))];

    const datasets = [];
    for (const choice of Object.keys(resultCountByTimestampByChoice)) {
        const resultCountByTimestamp = resultCountByTimestampByChoice[choice];
        datasets.push({
            label: choice,
            data: labels.map(lbl => resultCountByTimestamp[lbl] || 0),
            fill: false,
        });
    }

    return {labels, datasets};
}

export default function Trends({users = [], isLoadingReplies}) {
    return (
        <Container component="main">
            {isLoadingReplies && <p>Loading...</p>}
            {!isLoadingReplies && <TrendGraphCard users={users} />}
        </Container>
    );
}

import React from 'react';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

export default function DeleteConfirmationDialog({what, open, onConfirm, onClose}) {
    const handleDelete = () => {
        onConfirm();
        onClose();
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete <strong>{what}</strong>?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    No
                </Button>
                <Button variant="contained" startIcon={<DeleteIcon />} onClick={handleDelete} color="secondary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import EmailIcon from '@material-ui/icons/MailOutline';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import Header from '../Header';

import SpinnerButton from '../../../components/SpinnerButton';
import ErrorBanner from '../../../components/ErrorBanner';

import useActions from '../../../hooks/useActions';
import useMailingListStatus from '../../../hooks/useMailingListStatus';
import useAsyncCallback from '../../../hooks/useAsyncCallback';

const useStyles = makeStyles(theme => ({
    outerwrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    innerwrapper: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }
}));

export default function Notifications() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const actions = useActions();
    const [isSubscribed, mailingListFetchError] = useMailingListStatus();

    const [newAcceptsMarketing, setAcceptsMarketing] = React.useState(null);
    const notChanged = newAcceptsMarketing == null;
    const acceptsMarketing = notChanged ? (isSubscribed || false) : newAcceptsMarketing;
    
    const [handleSave, {isPending}] = useAsyncCallback(async () => {
        if (acceptsMarketing !== isSubscribed) {
            try {
                await actions.changeMailingListSubscribedStatus(acceptsMarketing);
            } catch (error) {
                enqueueSnackbar(`Error updating notification preferences: ${error.message}`, { variant: 'error' });
                return;
            }
        }

        setAcceptsMarketing(null);
        enqueueSnackbar('Notification preferences updated.', { variant: 'success' });
    });

    return (
        <div className={classes.outerwrapper}>
            <Header icon={EmailIcon}>Notification Settings</Header>

            <div className={classes.innerwrapper}>
                <form>
                    <Alert severity="info">
                        <Typography variant="body2">We only want to send you <strong>relevant</strong> promotional content <strong>infrequently</strong> when we have something worth sharing with you.</Typography>
                        <Typography variant="body2">We <strong>will not</strong> spam your inbox with irrelevant emails.</Typography>
                    </Alert>

                    <ErrorBanner text={mailingListFetchError} />

                    <FormControlLabel
                        control={
                        <Checkbox
                            color="primary"
                            checked={acceptsMarketing}
                            onChange={event => setAcceptsMarketing(event.target.checked)}
                        />
                        }
                        label="I am willing to receive the occasional promotional email (recommended)"
                    />
                </form>

                <SpinnerButton
                    variant="contained"
                    color="secondary"
                    disabled={notChanged}
                    pending={isPending}
                    onClick={handleSave}>
                        Save Settings
                </SpinnerButton>
            </div>
        </div>
    );
}
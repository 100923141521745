import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import SpinnerButton from '../../components/SpinnerButton';
import ErrorBanner from '../../components/ErrorBanner';
import ForgotPasswordDialog from './ForgotPasswordDialog';

import useActions from '../../hooks/useActions';
import useAsyncCallback from '../../hooks/useAsyncCallback';
import useToggle from '../../hooks/useToggle';

import * as auth from '../../api/auth';
import parseQueryParams from '../../common/parsequeryparams';


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#4455b7',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();

    const actions = useActions();
    const [userSignIn, {isPending, errorMsg}] = useAsyncCallback(actions.userSignIn);

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isPasswordResetOpen, openPasswordReset, closePasswordReset] = useToggle(false);
    
    const handleSendResetEmail = (email) => auth.forgotPassword(email);

    const signIn = (event) => {
        event.preventDefault();
        const {p:redirectTo} = parseQueryParams();
        userSignIn(username, password, redirectTo);
    };

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <Avatar className={classes.avatar}>
                <VpnKeyIcon />
            </Avatar>
            <Typography component="h1" variant="h6">Log in to Team Feels</Typography>
            <form className={classes.form} onSubmit={signIn}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    type="email"
                    disabled={isPending}
                    autoFocus
                    onChange={event => setUsername(event.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    disabled={isPending}
                    autoComplete="current-password"
                    onChange={event => setPassword(event.target.value)}
                />
                <Link href="#" variant="body2" onClick={openPasswordReset}>Forgot password?</Link>
                
                <SpinnerButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    pending={isPending}
                    className={classes.submit}
                >
                    Log In
                </SpinnerButton>
                <ErrorBanner text={errorMsg} />
                <Typography variant="subtitle2" color="textSecondary" align="right">Don't have an account yet? <Link component={RouterLink} to="/signup">Create an account now.</Link></Typography>
            </form>
            <ForgotPasswordDialog
                open={isPasswordResetOpen}
                onClose={closePasswordReset}
                onSendResetEmail={handleSendResetEmail} />
        </Container>

        
    );
}

import { saveAs } from 'file-saver';
import moment from 'moment'

import types from './actiontypes';
import history from '../common/browserhistory';

import * as auth from '../api/auth';
import * as api from '../api';

/* Util for dispatching an asynchronous triplet of actions */
function asyncThunk({type, redirectToOnSuccess, redirectToOnError, pendingPayload, actionFn}) {
    return async (dispatch, getState) => {
        dispatch({
            type,
            pending: true,
            success: false,
            failure: false,
            payload: pendingPayload
        });
        
        try {
            const payload = await actionFn(getState);

            dispatch({
                type,
                pending: false,
                success: true,
                failure: false,
                payload: payload || {}
            });

            if (redirectToOnSuccess) {
                history.push(redirectToOnSuccess);
            }

            return payload;
        } catch (error) {
            console.error('An error occured during TeamFeels action:', error);

            dispatch({
                type,
                pending: false,
                success: false,
                failure: true,
                error
            });

            if (redirectToOnError) {
                history.push(redirectToOnError);
            }

            throw error;
        }
    };
}

export function clearStatus(actionType) {
    return {
        type: types.CLEAR_STATUS,
        payload: { forActionType: actionType }
    };
}

export function loadExistingSessionData() {
    return asyncThunk({
        type: types.USER_CHANGE_INFO,
        actionFn: async () => {
            // if there is an existing session held in localstorage then this will first load it
            const jwtToken = await auth.getCurrentJwtIdToken();

            const {name, email, isEmailVerified, paddleSubscriptionID, isTrialExpired, trialExpiresAt} = await api.getAccountDetails(jwtToken);
            return {name, email, isEmailVerified, paddleSubscriptionID, isTrialExpired, trialExpiresAt};
        }
    });
}

export function userSignUp(name, email, password, acceptsMarketing) {
    return async () => {
        await auth.registerUser(name, email, password, acceptsMarketing);
        history.push(`/verify_registration?name=${name}&email=${email}`);
    };
}

export function userSignIn(email, password, redirectTo) {
    const redirectPath = redirectTo || '/welcome';

    return async (dispatch) => {
        try {
            await auth.loginUser(email, password);
        } catch (error) {
            if (error.code === 'UserNotConfirmedException') {
                history.push(`/verify_registration?email=${email}`);
                auth.resendSignupVerification(email);
                return;
            } else {
                throw error;
            }
        }

        const {name, isEmailVerified} = await dispatch(loadExistingSessionData());
        if (!isEmailVerified && !redirectPath.startsWith(encodeURIComponent('/confirm_email'))) {
            history.push(`/verify_email?name=${name}&email=${email}`);
            auth.resendEmailChangeVerification();
            return;
        }

        history.push(redirectPath);
    };
}

export function userSignOut() {
    return (dispatch) => {
        auth.logoutUser();
        dispatch({
            type: types.USER_SIGN_OUT
        });
        history.push('/signin');
    };
}

export function userChangeName(name) {
    return asyncThunk({
        type: types.USER_CHANGE_INFO,
        actionFn: async () => {
            await auth.changeNickName(name);
            return {name};
        }
    });
}

export function userChangePassword(oldPassword, newPassword) {
    return asyncThunk({
        type: types.USER_CHANGE_INFO,
        actionFn: async () => {
            await auth.changePassword(oldPassword, newPassword);
        }
    });
}

export function userChangeEmail(newEmail) {
    return asyncThunk({
        type: types.USER_CHANGE_INFO,
        actionFn: async () => {
            const resp = await api.changeEmail(await auth.getCurrentJwtIdToken(), newEmail);
            await auth.refreshSession();
            return {email: newEmail, isEmailVerified: false};
        }
    });
}

export function loadMailingListSubscribedStatus() {
    return asyncThunk({
        type: types.LOAD_IS_MAILING_LIST_SUBSCRIBED,
        actionFn: async () => {
            const resp = await api.getMailingListSubscriptionStatus(await auth.getCurrentJwtIdToken());

            return {
                isSubscribed: resp.isSubscribed
            };
        }
    });
}

export function changeMailingListSubscribedStatus(newStatus) {
    return asyncThunk({
        type: types.CHANGE_IS_MAILING_LIST_SUBSCRIBED,
        actionFn: async () => {
            await api.changeMailingListSubscriptionStatus(await auth.getCurrentJwtIdToken(), newStatus);

            return {
                isSubscribed: newStatus
            };
        }
    });
}

export function loadSubscriptionPlan() {
    return asyncThunk({
        type: types.LOAD_SUBSCRIPTION_DETAILS,
        actionFn: async () => {
            const resp = await api.loadSubscriptionPlan(await auth.getCurrentJwtIdToken());
            if (resp.error) {
                throw resp.error;
            }

            const user = resp.user || {};

            return {
                paddlePlanID: user.plan_id,
                paymentUpdateURL: user.update_url,
                lastPayment: user.last_payment || {},
                nextPayment: user.next_payment || {}
            };
        }
    });
}

export function startSubscriptionPlan(paddleSubscriptionID) {
    return asyncThunk({
        type: types.START_SUBSCRIPTION_PLAN,
        actionFn: async () => {
            // wait a moment for paddle to notify the backend webhook of the new subscription
            await new Promise(r => setTimeout(r, 3000));

            return {
                paddleSubscriptionID
            }
        }
    });
}

export function changeSubscriptionPlan(newPaddlePlanID) {
    return asyncThunk({
        type: types.CHANGE_SUBSCRIPTION_PLAN,
        actionFn: async () => {
            const resp = await api.changeSubscriptionPlan(await auth.getCurrentJwtIdToken(), newPaddlePlanID);
            if (resp.error) {
                throw resp.error;
            }

            return {
                newPaddlePlanID,
                nextPayment: resp.nextPayment
            };
        }
    });
}

export function cancelSubscriptionPlan() {
    return asyncThunk({
        type: types.CANCEL_SUBSCRIPTION_PLAN,
        actionFn: async () => {
            const resp = await api.cancelSubscriptionPlan(await auth.getCurrentJwtIdToken());
            if (resp.error) {
                throw resp.error;
            }
        }
    });
}

export function loadIntegrations() {
    return asyncThunk({
        type: types.LOAD_INTEGRATIONS,
        actionFn: async () => {
            const slackIntegrations = await api.slackListIntegrations(await auth.getCurrentJwtIdToken());

            return {
                slackIntegrations: slackIntegrations
            };
        }
    });
}

export function removeIntegration(id) {
    return asyncThunk({
        type: types.REMOVE_INTEGRATION,
        pendingPayload: {id},
        actionFn: async () => {
            await api.slackDeleteIntegration(await auth.getCurrentJwtIdToken(), id);

            return {
                id
            };
        }
    });
}

export function slackOAuthTokenExchange(code) {
    return asyncThunk({
        type: types.API_CALL,
        actionFn: async () => {
            await api.slackOAuthTokenExchange(await auth.getCurrentJwtIdToken(), code);
        }
    });
}

export function loadSurveys() {
    return asyncThunk({
        type: types.LOAD_SURVEYS,
        actionFn: async () => {
            const surveys = await api.listSurveys(await auth.getCurrentJwtIdToken());
            return {surveys};
        }
    });
}

export function loadSharedSurvey(surveyId) {
    return asyncThunk({
        type: types.LOAD_SHARED_SURVEY,
        actionFn: async () => {
            const surveys = await api.getSharedSurvey(surveyId);
            // it's more convenient for the backend to return a list, but it's either 0 or 1
            return surveys && surveys.length ? {sharedSurvey: surveys[0]} : null;
        }
    });
}

export function createSurvey(surveyDetails) {
    return asyncThunk({
        type: types.NEW_SURVEY,
        redirectToOnSuccess: '/surveys',
        actionFn: async () => {
            await api.addSurvey(await auth.getCurrentJwtIdToken(), surveyDetails);
        }
    });
}

export function editSurvey(surveyId, surveyDetails) {
    return asyncThunk({
        type: types.EDIT_SURVEY,
        redirectToOnSuccess: '/surveys',
        actionFn: async () => {
            await api.editSurvey(await auth.getCurrentJwtIdToken(), surveyId, surveyDetails);
        }
    });
}

export function updateSurveyTags(surveyId, tags) {
    return asyncThunk({
        type: types.UPDATE_SURVEY_TAGS,
        actionFn: async () => {
            await api.updateSurveyTags(await auth.getCurrentJwtIdToken(), surveyId, tags);
            return {id:surveyId, tags}
        }
    });
}

export function deleteSurvey(id) {
    return asyncThunk({
        type: types.DELETE_SURVEY,
        redirectToOnSuccess: '/surveys',
        pendingPayload: {id},
        actionFn: async () => {
            await api.deleteSurvey(await auth.getCurrentJwtIdToken(), id);
            return {id}
        }
    });
}

export function listReplies(surveyId, workspaceOrEnterpriseId, isAnon, isShared) {
    // if it's a shared survey we'll use an unauthenticated call
    if (isShared) {
        return asyncThunk({
            type: types.API_CALL,
            actionFn: async () => {
                const replies = await api.listSharedReplies(surveyId, workspaceOrEnterpriseId, isAnon);
                return replies
            }
        });
    }

    // ... otherwise loading all replies for an authenticated user
    return asyncThunk({
        type: types.API_CALL,
        actionFn: async () => {
            const replies = await api.listReplies(await auth.getCurrentJwtIdToken(), surveyId, workspaceOrEnterpriseId, isAnon);
            return replies;
        }
    });
}

export function shareSurvey(surveyId) {
    return asyncThunk({
        type: types.SHARE_SURVEY,
        actionFn: async () => {
            await api.shareSurvey(await auth.getCurrentJwtIdToken(), surveyId);
            return {id: surveyId};
        }
    });
}

export function unshareSurvey(surveyId) {
    return asyncThunk({
        type: types.UNSHARE_SURVEY,
        actionFn: async () => {
            await api.unshareSurvey(await auth.getCurrentJwtIdToken(), surveyId);
            return {id: surveyId};
        }
    });
}

export function exportToCSV(surveyTitle, userReplies) {
    return asyncThunk({
        type: types.API_CALL,
        actionFn: async () => {
            const csv = 
                '"ID","NAME","AVATAR","SURVEY","TIMESTAMP","REPLY"\n' +
                userReplies.flatMap(({slackUserId, name, avatar, responses}) =>
                    responses.length === 0
                     ? [`${slackUserId}","${name}","${avatar}","${surveyTitle}","",""`]
                     : responses.map(({timestamp, choice}) => {
                        const row = `"${slackUserId}","${name}","${avatar}","${surveyTitle}","${timestamp}","${choice}"`;
                       })
                    
                ).join('\n');

            const fileDormattedDate = moment().format('YYYY-MM-DD_HH-mm');

            const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
            saveAs(blob, `TeamFeelsExport_${surveyTitle}_${fileDormattedDate}.csv`);
        }
    });
}

import { useState, useCallback, useEffect, useRef } from 'react';

const INITIAL_STATE = {isPending:false, isFinished:false, errorMsg:null};

export default function useAsyncCallback(asyncFn) {
    const [{isPending, isFinished, result, errorMsg}, setState] = useState(INITIAL_STATE);
    const isMounted = useRef(true);

    const reset = useCallback(() => {
        setState(INITIAL_STATE);
    }, []);

    const doCallback = useCallback(async (...args) => {
        if (!isMounted.current) { return null; }
        try {
            setState({
                isPending: true,
                isFinished: false,
                errorMsg: null
            });

            const result = await asyncFn(...args);

            isMounted.current && setState({
                isPending: false,
                isFinished: true,
                result,
                errorMsg: null
            });

            return result;
        }
        catch (err) {
            console.error(err);
            isMounted.current && setState({
                isPending: false,
                isFinished: true,
                result: undefined,
                errorMsg: err.message || 'An error occurred'
            });

            return err;
        }
    }, [asyncFn]);

    // detect unmount
    useEffect(() => () => {
        isMounted.current = false;
    }, []);

    const isError = isFinished && errorMsg;
    const isSuccess = isFinished && !errorMsg;
    return [doCallback, {isPending, isFinished, isSuccess, isError, result, errorMsg}, reset];
}

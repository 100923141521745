import useSubscriptionDetails from './useSubscriptionDetails';
import getPlanByID from '../common/getplan';

export default function useCapFlags() {
    const [subscription, {isOnTrial}, isPending] = useSubscriptionDetails();

    if (isPending) {
        // state to use briefly before loaded from JSON file,
        // generally we want to go for locked down featuresets but we need to be
        // mindful that the page(s) are rendered in this mode so you may get a flash
        // of upsell content on page load (annoying)
        return {
            enablesAnonymity: true, // true to avoid flash of upsell content
            enablesMultipleTeams: false,
            enablesDataExports: false,
            surveysLimit: null
        };
    }

    const plan = getPlanByID(subscription.paddlePlanID, isOnTrial);
    return {
        enablesAnonymity: !!plan.allowsAnonymity,
        enablesMultipleTeams: !!plan.enablesMultipleTeams,
        enablesDataExports: !!plan.enablesDataExports,
        surveysLimit: plan.surveysLimit
    };
}
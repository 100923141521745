import moment from 'moment';

export function timeSince(timeMillis) {
    const theTime = moment(timeMillis);
    const isOld = moment().diff(theTime, 'days') > 7;
    return isOld ? theTime.format('LL') : theTime.fromNow();
}

export function timeUntil(timeMillis) {
    return moment(timeMillis).format('LL');
}

export function timeOfDay(timeMillis) {
    return moment(timeMillis).format('HH:mm');
}

export function dayOfWeek(timeMillis) {
    return moment(timeMillis).format('dddd');
}

export function shortToLongDayOFWeek(dayOfWeekShort) {
    return {
        'mon': 'Monday',
        'tue': 'Tuesday',
        'wed': 'Wednesday',
        'thu': 'Thursday',
        'fri': 'Friday',
        'sat': 'Saturday',
        'sun':' Sunday'
    }[dayOfWeekShort.toLowerCase()];
}
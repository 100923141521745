import React from 'react';
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "../common/browserhistory";
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';
import AppContainer from './app-container';
import {WebsiteThemeContainer} from './app-container';
import SignUp from './signup';
import SignIn from './signin';
import {CheckYourInbox, ConfirmRegistration, ConfirmEmail, SlackOAuthTokenExchange} from './confirm';
import PasswordReset from './passwordreset';
import Account from './account';
import Surveys from './surveys';
import SetupWizard from './setupwizard';
import {CreateSurvey, EditSurvey} from './surveyform';
import {SurveyDashboard, SharedSurveyDashboard} from './surveydashboard';

import RedirectIfNotLoggedIn from '../components/RedirectIfNotLoggedIn';

function createPalette(color) {
    return createMuiTheme({
        palette: {
            primary: color
        }
    }).palette.primary
}

const theme = createMuiTheme({
    palette: {
        tertiary: createPalette(teal)
    },
    overrides: {
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundColor: '#f8f8fb',
              minWidth: '400px'
            }
          }
        }
      }
});

export default function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={history}>
                <Switch>
                    <Route path="/signup">
                        <WebsiteThemeContainer page={<SignUp/>} />
                    </Route>
                    <Route path="/signin">
                        <WebsiteThemeContainer page={<SignIn/>} />
                    </Route>
                    <Route path="/verify_email">
                        <CheckYourInbox/>
                    </Route>
                    <Route path="/verify_registration">
                        <CheckYourInbox isNewSignup/>
                    </Route>
                    <Route path="/confirm">
                        <ConfirmRegistration/>
                    </Route>
                    <Route path="/confirm_email">
                        <RedirectIfNotLoggedIn/>
                        <ConfirmEmail/>
                    </Route>
                    <Route path="/reset_password">
                        <PasswordReset/>
                    </Route>
                    <Route path="/share/:surveyID">
                        <SharedSurveyDashboard/>
                    </Route>
                    <Route path="/slack/oauth">
                        <RedirectIfNotLoggedIn/>
                        <SlackOAuthTokenExchange/>
                    </Route>
                    <Route>
                        <AppContainer
                            defaultRouteChild={<Redirect to="/surveys" />}
                            pageRoutes={[
                                {path: '/welcome/:step?', Component: SetupWizard},
                                {path: '/account/:tab?', Component: Account},
                                {path: '/surveys', Component: Surveys},
                                {path: '/add', Component: CreateSurvey},
                                {path: '/edit/:surveyID', Component: EditSurvey},
                                {path: '/survey/:surveyID', Component: SurveyDashboard},
                            ]}
                        />

                        {/* pretty sure "last Redirect wins", so put this after AppContainer */}
                        <RedirectIfNotLoggedIn/>
                    </Route>
                </Switch>
            </Router>
        </MuiThemeProvider>
    );
}

import { types, isPending, isSuccess, isFailure } from '../actions';

const initialState = {
    paddlePlanID: null,
    paymentUpdateURL: null,
    lastPayment: {},
    nextPayment: {}
};

export default function subscription(state = initialState, action) {
    switch (action.type) {
        case types.LOAD_SUBSCRIPTION_DETAILS:
            if (isPending(action)) {
               return initialState;
            }
            if (isSuccess(action)) {
                return action.payload;
            }
            break;
        case types.CHANGE_SUBSCRIPTION_PLAN:
            if (isSuccess(action)) {
                return {
                    ...state,
                    paddlePlanID: action.payload.newPaddlePlanID,
                    nextPayment: action.payload.nextPayment
                };
            }
            break;
        case types.CANCEL_SUBSCRIPTION_PLAN:
            if (isSuccess(action)) {
                return initialState;
            }
            break;
    }

    return state;
}
import React from 'react';
import { Link } from "react-router-dom";
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';

import EmojiStrip from '../../components/EmojiStrip';
import SpinnerButton from '../../components/SpinnerButton';
import ErrorBanner from '../../components/ErrorBanner';
import PageHeadingBar from '../../components/PageHeadingBar';

import ChannelSelect from './ChannelSelect';
import DateTimeOrNowPicker from './DateTimeOrNowPicker';
import RepeatOptions from './RepeatOptions';

import useCapFlags from '../../hooks/useCapFlags';


const useStyles = makeStyles(theme => ({
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
    },
    questionAndAnswers: {
       paddingBottom: theme.spacing(2)
    },
    title: {
        width: '90%'
    },
    titleInput: {
        textAlign: 'center',
        fontSize: '1.2em'
    },
    questionInput: {
        fontSize: '1.6em',
        fontStyle: 'italic',
        lineHeight: 1.6
    },
    submitButton: {
        marginRight: theme.spacing(4)
    }
}));

function validateRepeatOptions(options) {
    const {
        mode,
        numWeeks,
        daysOfWeek,
        whenInMonth,
        dayInMonth,
        timeOfDay
    } = options;

    if (mode === 'never') {
        return true;
    }

    if (mode === 'weekly') {
        return daysOfWeek && daysOfWeek.length > 0 && !!timeOfDay;
    }

    if (mode === 'multiweek') {
        return numWeeks && numWeeks > 0 && !!timeOfDay;
    }

    if (mode === 'monthly') {
        return whenInMonth &&
            ( (whenInMonth === 'firstday' || whenInMonth === 'lastday') ||
             ((whenInMonth === 'firstweek' || whenInMonth === 'lastweek') && !!dayInMonth)
            ) && !!timeOfDay;
    }

    return false;
}

const DEFAULTS = {
    title: '',
    question: '',
    workspaceOrEnterpriseId: '',
    channel: '',
    emojis: ['😁', '🙂', '😑', '🙁', '😩'],
    isAnon: false,
    repeat: { mode: 'never'},
    postAt: Date.now(),
    postNow: true,
    tags: []
};

export default function SurveyForm({initialState = DEFAULTS, isPending, errorMsg, onSubmit, navCrumbs}) {
    const classes = useStyles();

    const {enablesAnonymity:allowAnon} = useCapFlags();

    // survey config state
    const [surveyTitle, setSurveyTitle] = React.useState(initialState.title);
    const [question, setQuestion] = React.useState(initialState.question);
    const [workspaceOrEnterpriseId, setWorkspaceOrEnterpriseId] = React.useState(initialState.workspaceOrEnterpriseId);
    const [channel, setChannel] = React.useState(initialState.channel);
    const [emojis, setEmojis] = React.useState(initialState.emojis);
    const [isAnon, setAnon] = React.useState(initialState.isAnon);
    const [postAt, setPostAt] = React.useState(initialState.postAt);
    const [isPostNow, setPostNow] = React.useState(initialState.postNow);
    const [repeatOptions, setRepeatOptions] = React.useState(initialState.repeat);

    const isValid =
        surveyTitle.trim().length > 0 &&
        question.trim().length > 0 &&
        workspaceOrEnterpriseId.trim().length > 0 &&
        channel.trim().length > 0 &&
        emojis.length > 0 &&
        validateRepeatOptions(repeatOptions);

    // keep postAt and repeat time-of-day in sync for better UX
    const handleOptionsChange = (newOptions) => {
        setRepeatOptions(newOptions);
        setPostAt(newOptions.timeOfDay); // note day/month/year remains unaltered
    };

    // keep postAt and repeat time-of-day in sync for better UX
    const handlePostAtChange = (newDateTime) => {
        const epochMillis = newDateTime.unix() * 1000;
        setPostAt(epochMillis);
        setRepeatOptions({...repeatOptions, timeOfDay: epochMillis});
    };

    const handleChannelChange = (c) => {
        setChannel(c.id);
        setWorkspaceOrEnterpriseId(c.workspaceOrEnterpriseId);
    }

    const handleAnonymityChange= (event) => {
        setAnon(event.target.checked);
    };

    const handleSubmit = () => {
        onSubmit({
            postAt: isPostNow ? Date.now() : postAt,
            title: surveyTitle,
            question,
            workspaceOrEnterpriseId,
            channel,
            emojis,
            isAnon: isAnon && allowAnon,
            repeat: repeatOptions,
            tags: initialState.tags
        });
    };

    const submitButton = (
        <SpinnerButton
            variant="contained"
            color="primary"
            className={classes.submitButton}
            onClick={handleSubmit}
            pending={isPending && !errorMsg}
            disabled={!isValid}
        >
            Save & Publish
        </SpinnerButton>
    );

    return (<>
        <form className={classes.form} noValidate>
            <PageHeadingBar navCrumbs={navCrumbs} action={submitButton} enableClose>
                <Container className={classes.headerContainer} maxWidth="sm">
                    <TextField
                        className={classes.title}
                        inputProps={{
                            className: classes.titleInput
                        }}
                        margin="normal"
                        required
                        fullWidth
                        name="title"
                        autoFocus
                        label="Survey Title"
                        value={surveyTitle}
                        disabled={isPending}
                        onChange={event => setSurveyTitle(event.target.value)}
                    />
                </Container>
            </PageHeadingBar>
            <div className={classes.questionAndAnswers}>
                <Container component="main" maxWidth="sm">
                    <ErrorBanner text={errorMsg} />

                    <TextField
                        inputProps={{
                            className: classes.questionInput
                        }}
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        label="What question do you want to ask?"
                        name="question"
                        value={question}
                        disabled={isPending}
                        onChange={event => setQuestion(event.target.value.replace(/\n/g, ''))}
                    />

                    <FormControl margin="normal" disabled={isPending} fullWidth required>
                        <FormLabel>Possible Answers:</FormLabel>
                        <EmojiStrip emojis={emojis} onChange={setEmojis} disabled={isPending} />
                    </FormControl>

                    <FormControlLabel disabled={!allowAnon || isPending} control={<Checkbox size="small" checked={isAnon && allowAnon} onChange={handleAnonymityChange}/>} label="Anonymity: Hide identity of respondants?" />
                    
                    {!allowAnon &&
                        <Chip
                            size="small"
                            color="primary"
                            icon={<ArrowUpwardIcon/>}
                            clickable
                            component={Link} to="/account/subscription"
                            label={'Available on our ' + window.PADDLE_CONFIG.plans[1].title + ' plan. Click to find out more...'}
                        />
                    }
                </Container>
            </div>
            <Container component="main" maxWidth="sm">
                <ChannelSelect
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    label="Slack Channel"
                    name="channel"
                    value={{channel, workspaceOrEnterpriseId}}
                    disabled={isPending}
                    onChange={handleChannelChange}
                />

                <FormControl margin="normal" disabled={isPending} fullWidth required>
                    <FormLabel>When To Post:</FormLabel>
                    <DateTimeOrNowPicker
                        datetime={new Date(postAt)}
                        isNow={isPostNow}
                        onChangeDateTime={handlePostAtChange}
                        onChangeIsNow={setPostNow}
                        disablePast
                        maxFutureDays={100}
                    />
                </FormControl>

                <RepeatOptions
                    enableCustomTimeOfDay={isPostNow}
                    options={repeatOptions}
                    onChangeOptions={handleOptionsChange}
                    disabled={isPending}
                />
            </Container>
        </form>
    </>);
}

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useCapFlags from './useCapFlags';
import { actions } from '../actions';

export default function useIntegrations() {
    const {enablesMultipleTeams} = useCapFlags(); 
    const {slackIntegrations, isLoading, errorMsg} = useSelector(state => state.integrations);

    // load on first mount
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadIntegrations());
    }, []);

    const enabledSlackIntegrations = enablesMultipleTeams ? slackIntegrations : slackIntegrations.slice(0, 1);
    const disabledSlackIntegrations = enablesMultipleTeams ? [] : slackIntegrations.slice(1);

    return [
        enabledSlackIntegrations,
        disabledSlackIntegrations,
        isLoading,
        errorMsg,
        enablesMultipleTeams || enabledSlackIntegrations.length === 0
    ];
}
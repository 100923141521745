import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));


export default function SpinnerButton({pending, children, disabled, ...props}) {
    const classes = useStyles();

    return (
        <Button {...props} disabled={disabled || pending}>
            {children}
            {pending && <CircularProgress size={24} className={classes.progress}/>}
        </Button>
    );
}

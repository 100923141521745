import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import ChipInput from 'material-ui-chip-input';

import SpinnerButton from '../../components/SpinnerButton';
import ErrorBanner from '../../components/ErrorBanner';

import useActions from '../../hooks/useActions';
import useAsyncCallback from '../../hooks/useAsyncCallback';


const useStyles = makeStyles(theme => ({

}));

export default function EditTagsDialog({surveyId, tags, open, onClose}) {
    const actions = useActions();

    const [updateSurveyTags, {isPending, isSuccess, errorMsg}] = useAsyncCallback(actions.updateSurveyTags);

    const [chips, setChips] = React.useState(tags);

    const onClickDone = () => {
        updateSurveyTags(surveyId, chips);
    }

    const onClickCancel = () => {
        setChips(tags); // reset the tag state
        onClose();
    }

    React.useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess]);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Edit Tags</DialogTitle>
            <DialogContent>
                <ChipInput
                    fullWidth
                    defaultValue={chips}
                    onChange={(chips) => setChips(chips)}
                />
                <ErrorBanner text={errorMsg} />
            </DialogContent>
            <DialogActions>
                <Button disabled={isPending} onClick={onClickCancel} color="secondary">
                    Cancel
                </Button>
                <SpinnerButton pending={isPending} onClick={onClickDone} color="primary">
                    Done
                </SpinnerButton>
            </DialogActions>
        </Dialog>
    );
}

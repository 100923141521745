import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Header({icon, children}) {
    const Icon = icon;
    return (
        <div style={{display:'flex', alignItems:'flex-end', marginBottom:'1em'}}>
            {Icon ? <Icon fontSize='large'/> : null}
            <Typography variant="h5" style={{marginLeft: '0.5em'}}>{children}</Typography>
        </div>
    );
}
